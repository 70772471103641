<div [id]="value" class="content">
    <div class="cardTitle">
        <h2 class="title" translate>homePage.values.title</h2>
    </div>

    <div class="cardPrice">
        <mat-icon
            class="icon"
            aria-hidden="false"
            aria-label="Example home icon"
            >local_activity</mat-icon
        >

        <p
            class="text"
            [innerHTML]="'homePage.values.full_price' | translate"
        ></p>
    </div>

    <div class="cardPrice meia">
        <mat-icon
            class="icon"
            aria-hidden="false"
            aria-label="Example home icon"
            >local_activity</mat-icon
        >

        <p
            class="text"
            [innerHTML]="'homePage.values.half_price' | translate"
        ></p>
    </div>

    <div class="cardDesc">
        <div class="cardStudent">
            <p class="cardDesc__estudent" translate>
                homePage.values.half_price_student
            </p>
        </div>

        <div class="cardText">
            <p class="cardText__residenteAm" translate>
                homePage.values.half_price_residents
            </p>
        </div>
    </div>

    <div class="cardPrice meia">
        <mat-icon
            class="icon"
            aria-hidden="false"
            aria-label="Example home icon"
            >local_activity</mat-icon
        >
        <p class="text">
            <strong translate>homePage.values.free</strong>
        </p>
    </div>

    <div class="cardDesc">
        <div class="cardKids">
            <p class="cardKids__kids" translate>homePage.values.free_kids</p>
        </div>

        <div class="cardOld">
            <p class="cardOld__olds" translate>homePage.values.free_olds</p>
        </div>

        <div class="cardPcd">
            <p class="cardPcd__pcd" translate>homePage.values.free_pwd</p>
        </div>

        <div class="cardMilitary">
            <p class="cardMilitary__military" translate>
                homePage.values.free_military
            </p>
        </div>
    </div>

    <div class="cardBtnFooter">
        <app-button
            icon="local_activity"
            text="{{ 'appHeader.menu_buy_ticket' | translate }}"
            link="/buyticket"
        ></app-button>
    </div>
</div>
