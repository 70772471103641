import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card-values-ticket',
  templateUrl: './card-values-ticket.component.html',
  styleUrls: ['./card-values-ticket.component.scss'],
})
export class CardValuesTicketComponent {
  @Input() value: string = '';
}
