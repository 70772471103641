<div class="container-fluid cardMain" fxLayout="column" fxLayoutAlign="center">
  <div class="container cardContent">
    <div class="cardBtnHamburguer">
      <button
        (click)="menuToogle.toggle($event)"
        class="btnMenu"
        mat-icon-button
      >
        <mat-icon style="color: #ffffff">menu</mat-icon>
      </button>
    </div>

    <div class="empty_spacing"></div>

    <div class="logo_container">
      <div class="cardLogo" fxFlex="auto">
        <img
          class="cardLogo__img"
          src="../../../assets/images/logo-cigs-onca.svg"
          alt="logo zootech"
          routerLink=""
        />
      </div>

      <div class="verticalLine"></div>

      <div class="cardLogo" fxFlex="auto">
        <img
          class="cardLogo__img"
          src="../../../assets/images/logoZootech.svg"
          alt="logo zootech"
          routerLink=""
        />
      </div>
    </div>

    <div class="cardTranslate">
      <div [hidden]="isHiddenBtnTranslate">
        <img
          class="cardLogo__img"
          src="../../../assets/icon/translate.svg"
          alt="logo zootech"
          routerLink=""
        />

        <select
          class="select_lang"
          [ngModel]="selected_lang"
          (ngModelChange)="handleChangeLanguage($event)"
        >
          <option *ngFor="let lang of langs" [value]="lang.value">
            {{ lang.label }}
          </option>
        </select>
      </div>

      <div [hidden]="isHiddenBtnTranslate">
        <select
          class="select_lang_mobile"
          [ngModel]="selected_lang"
          (ngModelChange)="handleChangeLanguage($event)"
        >
          <option *ngFor="let lang of langs" [value]="lang.value">
            {{ lang.value }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <app-menu
    [isHiddenBtnTranslate]="isHiddenBtnTranslate"
    [defineTop]="defineTop"
    #menuToogle
  ></app-menu>
</div>
