<div class="cardHeader">
  <app-header></app-header>
</div>

<section class="container cardMain">
  <mat-card class="containerMain">
    <mat-card-content class="content">
      <h1 class="title">Ops! 404</h1>
      <div class="cardIcon">
        <i class="fa-solid fa-face-frown fa-10x icon" aria-hidden="true"></i>
      </div>
      <h3 class="desc" translate="notFound.text"></h3>
    </mat-card-content>
  </mat-card>

  <section class="cardActions">
    <div>
      <button
        class="customerBtn"
        routerLink=""
        translate="notFound.text_btn"
      ></button>
    </div>
  </section>
</section>
