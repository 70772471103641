import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';

import { MatCheckboxChange } from '@angular/material/checkbox';
import { ErrorStateMatcher } from '@angular/material/core';
import { State } from 'src/app/models/state';
import { LocationService } from 'src/app/services/location.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnChanges {
  typeInput: boolean = false;
  @Input() registerForm: FormGroup;
  @Input() addedTourist: any;
  @Input() removedeTourist: any;
  @Input() qtdTouristsForm: any;
  @Input() isChecked: boolean = false;
  @Input() formgroupName: string;
  @Output() checkedDatas: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() checkedIsForeign: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  maskCpf = '000.000.000-99';
  listCountries: any[] = [];
  listStatesBrazil: State[] = [];
  matcher = new MyErrorStateMatcher();
  params: string;
  currentLang: string;
  get tourists(): FormArray {
    return <FormArray>this.registerForm?.get('tourists');
  }
  constructor(
    private fb: FormBuilder,
    private countryService: LocationService,
    private rootFormGroup: FormGroupDirective
  ) {}

  ngOnInit() {
    this.createRegisterForm();
    this.listAllCountries();
    this.listAllStateBr();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const qtdChanges = changes['qtdTouristsForm'];

    if (qtdChanges?.currentValue > qtdChanges?.previousValue) {
      this.addTourist();
    } else {
      this.removeTourist();
    }
  }

  listAllCountries() {
    this.countryService.getAllCountries().subscribe((res) => {
      return res.map((country): any => {
        this.currentLang = localStorage.getItem('lang') as string;
        if (this.currentLang === 'pt') {
          const newListCountries = country.translations['pt'];
          this.listCountries.sort();
          this.listCountries.push(newListCountries);
        } else {
          const newListCountriesEn = country.name;
          this.listCountries.sort();
          this.listCountries.push(newListCountriesEn);
        }
      });
    });
  }

  listAllStateBr() {
    this.countryService.getAllStateBr().subscribe({
      next: (res) => {
        this.listStatesBrazil = res;
      },
    });
  }

  changeConfirmDatas(event: any) {
    this.checkedDatas.emit(event.checked);
  }

  createRegisterForm() {
    this.registerForm = this.rootFormGroup.control.get(
      this.formgroupName
    ) as FormGroup;
  }

  createTourist(): FormGroup {
    return this.fb.group({
      country: ['', [Validators.required]],
      state: [''],
    });
  }

  addTourist() {
    this.tourists.push(this.createTourist());
  }

  removeTourist() {
    const lastIndex = this.tourists?.length - 1;
    this.tourists?.removeAt(lastIndex);
  }

  changeTypeInput(value: MatCheckboxChange) {
    if (value.checked === true) {
      this.typeInput = true;
      this.checkedIsForeign.emit(true);
    } else {
      this.typeInput = false;
      this.checkedIsForeign.emit(false);
    }
  }

  getCurrentLang() {
    this.currentLang = localStorage.getItem('lang') as string;

    if (this.currentLang === 'pt') {
      this.params = 'pt';
    } else {
      this.params = '';
    }
  }

  get docIdentification() {
    return this.registerForm.get('docIdentification');
  }

  get firstName() {
    return this.registerForm.get('firstName');
  }
  get email() {
    return this.registerForm.get('email');
  }
}
