import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TicketInstitution } from '../models/ticket-institution';
import { TypeInstitution } from '../models/type-institution';

@Injectable({
    providedIn: 'root',
})
export class InstitutionService {
    BASE_URL = environment.apiURL;
    constructor(private http: HttpClient) {}

    createTicketInstitution(
        ticketInstitution: TicketInstitution
    ): Observable<TicketInstitution> {
        const url = '/institutions';

        return this.http.post<TicketInstitution>(
            this.BASE_URL + url,
            ticketInstitution
        );
    }

    getAllInstitutionTypes(): Observable<TypeInstitution[]> {
        const url = '/institutions/types';
        return this.http.get<TypeInstitution[]>(this.BASE_URL + url);
    }

    getAvailableHour(date: string) {
        const url = `/institutions/available/hours/${date}`;
        return this.http.get<any>(this.BASE_URL + url);
    }
}
