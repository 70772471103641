import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { GalleryModule } from 'ng-gallery';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RouterModule } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { CountdownModule } from 'ngx-countdown';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';

import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { ButtonComponent } from './button/button.component';
import { TitleComponent } from './title/title.component';
import { CardValuesTicketComponent } from './card-values-ticket/card-values-ticket.component';
import { CarouselComponent } from './carousel/carousel.component';
import { OpeningHoursComponent } from './opening-hours/opening-hours.component';
import { AdressComponent } from './adress/adress.component';
import { MapComponent } from './map/map.component';
import { RulesZooComponent } from './rules-zoo/rules-zoo.component';
import { MenuComponent } from './menu/menu.component';
import { StepsComponent } from './steps/steps.component';
import { CountdownComponent } from './countdown/countdown.component';
import { CalendaryComponent } from './calendary/calendary.component';
import { CardSelectDaysComponent } from './card-select-days/card-select-days.component';
import { CardMsgComponent } from './card-msg/card-msg.component';
import { AbstractComponent } from './abstract/abstract.component';
import { TypeTicketComponent } from './type-ticket/type-ticket.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MenuTranslateComponent } from './menu-translate/menu-translate.component';
import { InfoMgsComponent } from './info-mgs/info-mgs.component';
import { DialogComponent } from './dialog/dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { CardVisitInstitutionComponent } from './card-visit-institution/card-visit-institution.component';

@NgModule({
    declarations: [
        HeaderComponent,
        HeaderMenuComponent,
        ButtonComponent,
        TitleComponent,
        CardValuesTicketComponent,
        CarouselComponent,
        OpeningHoursComponent,
        AdressComponent,
        MapComponent,
        RulesZooComponent,
        MenuComponent,
        StepsComponent,
        CountdownComponent,
        CalendaryComponent,
        CardSelectDaysComponent,
        CardMsgComponent,
        AbstractComponent,
        TypeTicketComponent,
        MenuTranslateComponent,
        InfoMgsComponent,
        DialogComponent,
        CardVisitInstitutionComponent,
    ],
    imports: [
        CommonModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        MatListModule,
        GalleryModule,
        RouterModule,
        CountdownModule,
        MatDatepickerModule,
        MatDialogModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        TranslateModule,
        FormsModule,
    ],
    exports: [
        HeaderComponent,
        HeaderMenuComponent,
        ButtonComponent,
        TitleComponent,
        CardValuesTicketComponent,
        CarouselComponent,
        OpeningHoursComponent,
        AdressComponent,
        MapComponent,
        RulesZooComponent,
        MenuComponent,
        StepsComponent,
        CountdownComponent,
        CalendaryComponent,
        CardSelectDaysComponent,
        CardMsgComponent,
        AbstractComponent,
        TypeTicketComponent,
        InfoMgsComponent,
        DialogComponent,
        CardVisitInstitutionComponent,
    ],
})
export class ComponentsModule {}
