<div class="container containerMain">
    <div class="cardCountdowMain">
        <div translate>buyTicket.countdown_text_1</div>

        <countdown
            style="margin: 3px"
            [config]="config"
            (event)="handleEvent($event)"
        >
        </countdown>

        <div translate>buyTicket.countdown_text_2</div>
    </div>
</div>
