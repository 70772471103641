<div class="container-fluid containerMain">
    <div class="container cardMainSteps">
        <div
            class="pointerOne"
            [ngStyle]="pointerOne"
            [ngClass]="step === 1 ? 'active' : check1"
        >
            <div class="content">
                <h1 class="title">1</h1>
                <h3 class="subtitle">{{ configStep.textStep1 }}</h3>
            </div>
        </div>
        <div
            [ngStyle]="pointerOne"
            class="pointerMidlle"
            id="step2"
            [ngClass]="step === 2 ? 'active' : check2"
        >
            <div class="content">
                <h1 class="title">2</h1>
                <h3 class="subtitle">{{ configStep.textStep2 }}</h3>
            </div>
        </div>
        <div
            [ngStyle]="pointerOne"
            class="pointerMidlle"
            id="step3"
            [ngClass]="step === 3 ? 'active' : check3"
        >
            <div class="content">
                <h1 class="title">3</h1>
                <h3 class="subtitle">{{ configStep.textStep3 }}</h3>
            </div>
        </div>
        <div
            [hidden]="isHiddenLastChild"
            class="pointerReverse"
            id="step4"
            [ngClass]="step === 4 ? 'active' : check4"
        >
            <div class="content">
                <h1 class="title">4</h1>
                <h3 class="subtitle">Comprovante</h3>
            </div>
        </div>
    </div>
</div>
