import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-msg-request',
  templateUrl: './msg-request.component.html',
  styleUrls: ['./msg-request.component.scss'],
})
export class MsgRequestComponent implements OnInit {
  @Input() numberProtocol: number;

  costumerBtn: any = {
    'padding-left': '32px',
    'padding-right': '32px',
    'background-color': 'var(--color-secondary)',
  };
  constructor() {}

  ngOnInit() {}
}
