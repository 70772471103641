<mat-card class="container containerMain msgInfo">
  <h1 class="title">Ops!</h1>
  <div class="contentIcon">
    <i class="fa-regular fa-circle-xmark circle-alert" aria-hidden="true"></i>
  </div>

  <div class="cardMainDays">
    <p class="description">
      {{ msg }}
    </p>
  </div>
</mat-card>
