import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-info-mgs',
  templateUrl: './info-mgs.component.html',
  styleUrls: ['./info-mgs.component.scss'],
})
export class InfoMgsComponent {
  @Input() msg: string;
}
