import { Component, HostListener, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
interface ILanguage {
  label: string;
  value: string;
}
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  isMenuOpen: boolean = true;
  @Input() defineTop: any;
  @Input() isHiddenBtnTranslate: boolean = true;
  @HostListener('document:click') resetToggle() {
    this.isMenuOpen = true;
  }

  langs: Array<ILanguage> = [];
  selected_lang: any = localStorage.getItem('lang') || 'pt';

  constructor(private translate: TranslateService) {
    this.langs = [
      { label: 'Português', value: 'pt' },
      { label: 'English', value: 'en' },
    ];

    translate.setDefaultLang('pt');
    translate.use('pt');
  }

  ngOnInit(): void {
    this.selected_lang = localStorage.getItem('lang') || 'pt';
    this.translate.use(this.selected_lang);
  }

  toggle(e: any) {
    e.stopPropagation();
    this.isMenuOpen = !this.isMenuOpen;
  }

  handleChangeLanguage(item: any) {
    localStorage.setItem('lang', item);
    this.translate.use(item);
    window.location.reload();
  }
}
