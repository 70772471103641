import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() text: string = '';
  @Input() icon: string = '';
  @Input() link: string;
  @Input() isHidden: boolean = false;
  @Input() isDisabled: boolean;
  @Input() customerBtn: any = {
    'background-color': 'var(--color-secondary)',
    padding: '7px',
  };
}
