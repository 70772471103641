<div class="container cardContainerMain">
  <header>
    <h3 class="title" translate>buyTicket.receipt.receipt_title</h3>
  </header>

  <section>
    <mat-card class="card">
      <div class="containerHeader">
        <div class="cardDate">
          <div class="calendary">
            <i
              class="fa-solid fa-calendar-day fa-xl icon"
              aria-hidden="true"
            ></i>
          </div>

          <div class="cardDescription ml">
            <p class="text">
              <strong translate> buyTicket.receipt.purchase_date </strong>

              {{ ticketMain.ticket?.create | date: "dd/MM/YYYY " }}
            </p>

            <p
              class="text"
              [innerHTML]="'buyTicket.receipt.opening_hours' | translate"
            ></p>
          </div>
        </div>

        <div class="cardLocation">
          <div class="location">
            <i
              class="fa-solid fa-location-dot fa-xl icon"
              aria-hidden="true"
            ></i>
          </div>

          <p
            class="text ml"
            [innerHTML]="'buyTicket.receipt.localization' | translate"
          ></p>
        </div>

        <div class="cardMsg">
          <app-card-msg
            msg="{{ 'buyTicket.receipt.receipt_warn' | translate }}"
          ></app-card-msg>
        </div>
      </div>
    </mat-card>

    <div *ngFor="let qrCode of ticketsSecondary">
      <div>
        <mat-card class="cardTicketHeader">
          <p class="text">
            <strong class="titleTicket" translate> global.ticket </strong>
          </p>
        </mat-card>

        <mat-card class="cardTicketBody">
          <div class="cardContent">
            <p class="text">
              {{ "buyTicket.receipt.bought_day" | translate }}
              {{ qrCode.ticket?.create | date: "dd/MM/YYYY " }}
              {{ "global.at" | translate }}
              {{ qrCode.ticket.create | date: " HH:mm " }}
            </p>

            <p class="text">
              {{ "global.ticket" | translate }} -
              {{ qrCode.ticket_type?.ticket_name }}
            </p>

            <p>
              <strong class="text">
                {{ "buyTicket.receipt.scheduled_day" | translate }}
                -
                {{ qrCode.ticket?.operating_day.day | date: "dd/MM/YYYY " }}
                -
                {{
                  qrCode.ticket?.shift_type === "SHIFT_MORNING"
                    ? morning
                    : afternoon
                }}</strong
              >
            </p>

            <p class="text">R$ {{ qrCode.ticket_type?.ticket_value }},00</p>

            <div
              class="cardMainMsg"
              *ngIf="qrCode.ticket_type?.ticket_message !== ''"
            >
              <div class="content cardMainMsg__msg">
                <p class="m-0 msg">
                  {{ qrCode.ticket_type?.ticket_message }}
                </p>
              </div>
            </div>
          </div>

          <div class="cardQrCode__secondary">
            <div class="cardImg">
              <img
                class="cardImg__img qrCodeChild"
                [src]="qrCode?.image"
                alt=""
              />
            </div>

            <div class="cardSerial">
              <p>
                <strong class="text">
                  {{ qrCode?.number }}
                </strong>
              </p>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </section>
</div>
