<div>
    <mat-card class="cardMain">
        <mat-card-title translate>msgRequest.congratulations</mat-card-title>

        <mat-card-content class="cardMainContent">
            <div class="cardIcon">
                <i
                    class="fa-regular fa-circle-check fa-9x colorIcon"
                    aria-hidden="true"
                ></i>
            </div>

            <div class="cardMsgSucess">
                <h3 class="sub costumerMsg" translate>
                    msgRequest.costumer_msg
                </h3>
            </div>

            <div class="cardOrientation">
                <h3 class="costumerOrient" translate>
                    msgRequest.costumer_orient
                </h3>
            </div>

            <div class="cardProtocol">
                <h3 class="numberProtocol">
                    <strong class="strong"> Nº do protocolo: </strong>
                    {{ numberProtocol }}
                </h3>
            </div>
        </mat-card-content>

        <mat-card-actions>
            <div class="cardActions">
                <app-button
                    link="/"
                    [customerBtn]="costumerBtn"
                    [isHidden]="true"
                    text="{{ 'msgRequest.costumer_button' | translate }}"
                >
                </app-button>
            </div>
        </mat-card-actions>
    </mat-card>
</div>
