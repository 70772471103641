import { Component, Input } from '@angular/core';
import { ConfigStep } from 'src/app/models/configStep';

@Component({
    selector: 'app-steps',
    templateUrl: './steps.component.html',
    styleUrls: ['./steps.component.scss'],
})
export class StepsComponent {
    @Input() step: number = 1;
    @Input() contStep = 0;
    @Input() isHiddenLastChild: boolean;
    @Input() pointerOne: any;
    @Input() configStep: ConfigStep = {
        textStep1: '',
        textStep2: '',
        textStep3: '',
    };

    check1 = 'checked';
    @Input() check2: string = '';
    @Input() check3: string = '';
    check4 = '';
    textPrevious: string = 'Voltar';

    @Input() isDisabled = true;
}
