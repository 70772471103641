import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Scheduling } from '../models/scheduling';

@Injectable({
  providedIn: 'root',
})
export class SchedulingService {
  BASE_URL = environment.apiURL;
  constructor(private http: HttpClient) {}

  getAllOperationDay(): Observable<Scheduling[]> {
    const url = '/operating-days';
    return this.http.get<Scheduling[]>(this.BASE_URL + url);
  }
  getAllOperationDayActive(): Observable<Scheduling[]> {
    const url = '/operating-days/list-by-actives';
    return this.http.get<Scheduling[]>(this.BASE_URL + url);
  }

  getOperationDayByDate(date: string) {
    const url = `/operating-days/by-date`;
    return this.http.get<Scheduling>(this.BASE_URL + url, {
      params: {
        date: date,
      },
    });
  }

  getMsgDefault(key: string) {
    const url = `/settings/${key}`;
    return this.http.get<any>(this.BASE_URL + url);
  }
}
