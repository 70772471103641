<h1 mat-dialog-title class="title">{{ title }}</h1>
<div class="cardMainIcon" [hidden]="isHiddenIcon">
    <i class="fa-regular fa-circle-xmark icon" aria-hidden="true"></i>
</div>
<div mat-dialog-content class="msg" [ngStyle]="paddingMsg">{{ msg }}</div>
<div mat-dialog-actions class="cardContentActions">
    <div [ngClass]="typeBuyTicket">
        <div class="btn" [ngStyle]="widthBtn" [hidden]="isHidden">
            <app-button
                [isHidden]="true"
                [customerBtn]="customerBtn"
                (click)="dialogRef.close(false)"
                [text]="textBtnDont"
            ></app-button>
        </div>

        <div class="btn">
            <app-button
                [isHidden]="true"
                (click)="dialogRef.close(true)"
                [text]="textBtnYes"
            ></app-button>
        </div>
    </div>
</div>
