import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { ComponentsModule } from '../components/components.module';
import { MatCardModule } from '@angular/material/card';
import { BuyTicketComponent } from './buy-ticket/buy-ticket.component';
import { RegisterComponent } from './buy-ticket/forms/register/register.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ReactiveFormsModule } from '@angular/forms';
import { InputMaskModule } from '@ngneat/input-mask';
import { NgxMaskModule } from 'ngx-mask';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';

import { PagTesouroComponent } from './buy-ticket/pag-tesouro/pag-tesouro.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalComponentPagTesouro } from './buy-ticket/modal/modal.component';
import { ReceiptTicketsComponent } from './buy-ticket/receipt-tickets/receipt-tickets.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { BuyTicketInstitutionComponent } from './buy-ticket-institution/buy-ticket-institution.component';
import { VisitRequestComponent } from './buy-ticket-institution/forms/visit-request/visit-request.component';
import { RepresentativeComponent } from './buy-ticket-institution/forms/representative/representative.component';
import { DisplayDatasComponent } from './buy-ticket-institution/display-datas/display-datas.component';
import { MsgRequestComponent } from './buy-ticket-institution/msg-request/msg-request.component';
import { TranslateModule } from '@ngx-translate/core';
import { SafePipe } from '../shared/pipes/safe.pipe';
import { Error404Component } from './error404/error404.component';

@NgModule({
  declarations: [
    HomeComponent,
    BuyTicketComponent,
    RegisterComponent,
    PagTesouroComponent,
    ModalComponentPagTesouro,
    ReceiptTicketsComponent,
    BuyTicketInstitutionComponent,
    VisitRequestComponent,
    RepresentativeComponent,
    DisplayDatasComponent,
    MsgRequestComponent,
    SafePipe,
    Error404Component,
  ],
  imports: [
    CommonModule,
    ComponentsModule,
    MatCardModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    InputMaskModule,
    NgxMaskModule.forRoot(),
    MatSelectModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    RouterModule,
    MatDatepickerModule,
    TranslateModule,
  ],
  exports: [
    HomeComponent,
    BuyTicketComponent,
    RegisterComponent,
    PagTesouroComponent,
    ModalComponentPagTesouro,
    ReceiptTicketsComponent,
    BuyTicketInstitutionComponent,
    VisitRequestComponent,
    RepresentativeComponent,
    DisplayDatasComponent,
    MsgRequestComponent,
  ],
})
export class PagesModule {}
