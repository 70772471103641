import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TicketInstitution } from 'src/app/models/ticket-institution';

@Component({
  selector: 'app-display-datas',
  templateUrl: './display-datas.component.html',
  styleUrls: ['./display-datas.component.scss'],
})
export class DisplayDatasComponent implements OnInit {
  @Input() datasVisitRequest: TicketInstitution;
  typeInstitution: string;
  constructor(private datePipe: DatePipe) {}

  ngOnInit() {}

  transformDate(date: Date | any) {
    const dateTransform = this.datePipe.transform(date, 'dd/MM/YYYY');
    return dateTransform;
  }

  setTypeOrganizationName(value: any) {
    if (value === 1) {
      this.typeInstitution = 'Pública';
    }
    if (value === 2) {
      this.typeInstitution = 'Religiosa';
    }
    if (value === 3) {
      this.typeInstitution = 'ONG/Associação';
    }
    if (value === 4) {
      this.typeInstitution = 'Privada';
    }
  }
}
