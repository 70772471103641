<div class="container-fluid cardMain">
  <div class="container menu_container">
    <ul class="listItem">
      <li class="item">
        <a class="item" href="#value" translate>appHeader.menu_values</a>
      </li>

      <li class="item">
        <a class="item" href="#hour" translate>appHeader.menu_opening_hours</a>
      </li>

      <li class="item">
        <a class="item" href="#adress" translate>appHeader.menu_address</a>
      </li>

      <li class="item">
        <a class="item" href="#rules" translate>appHeader.menu_zoo_rules</a>
      </li>
    </ul>

    <div class="menu_actions">
      <div class="cardMainBtn" *ngIf="!isHiddenBtn">
        <app-button
          icon="local_activity"
          text="{{ 'appHeader.menu_buy_ticket' | translate }}"
          link="/buyticket"
        ></app-button>
      </div>

      <div class="cardMainBtn btnInstitution" *ngIf="!isHiddenBtnInstitution">
        <app-button
          icon="local_activity"
          text="{{ 'appHeader.menu_request_institution' | translate }}"
          link="/buyTicketInstitution"
        ></app-button>
      </div>
    </div>
  </div>
</div>
