import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-receipt-tickets',
    templateUrl: './receipt-tickets.component.html',
    styleUrls: ['./receipt-tickets.component.scss'],
})
export class ReceiptTicketsComponent {
    @Input() ticketsSecondary: any[] = [];
    @Input() ticketMain: any = {};

    selected_lang: any = localStorage.getItem('lang')|| 'pt';

    morning =
        this.selected_lang === 'pt' ? 'Manhã' : 'Morning';

    afternoon =
        this.selected_lang === 'pt' ? 'Tarde' : 'Afternoon';
}
