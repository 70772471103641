import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TypeTicket } from 'src/app/models/typeTicket';

@Component({
  selector: 'app-type-ticket',
  templateUrl: './type-ticket.component.html',
  styleUrls: ['./type-ticket.component.scss'],
})
export class TypeTicketComponent {
  title: string = 'Tipos de Ingressos';
  subtitle: string = 'escolha o(s) tipo(s) de ingresso(s).';
  textQtd: string = 'QTD';

  @Output() setTicket: EventEmitter<any> = new EventEmitter<any>();
  @Output() removeTickets: EventEmitter<any> = new EventEmitter<any>();
  @Output() onChangeQtdTicket: EventEmitter<any> = new EventEmitter<any>();
  @Input() itensCardTypeTickets: TypeTicket[] = [];
  @Input() qtyVacanciesAvailable: number;

  addTicket(id: number | undefined) {
    let card = this.itensCardTypeTickets.find((item) => item.ticket_id === id);

    if (card?.ticket_id === 1) {
      this.qtyVacanciesAvailable--;
      card.qtdTickets++;
      this.onChangeQtdTicket.emit(card.qtdTickets);
      this.setTicket.emit({ id, qtd: card.qtdTickets });
    } else {
      this.qtyVacanciesAvailable--;
      card!.qtdTickets++;
      this.setTicket.emit({ id, qtd: card?.qtdTickets });
    }
  }

  removeTicket(id: number | undefined) {
    let card = this.itensCardTypeTickets.find((item) => item.ticket_id === id);
    if (card?.qtdTickets === 0) return;
    if (card?.ticket_id === 1) {
      this.qtyVacanciesAvailable++;
      card.qtdTickets--;
      this.onChangeQtdTicket.emit(card.qtdTickets);
      this.removeTickets.emit({ id, qtd: card?.qtdTickets });
    } else {
      this.qtyVacanciesAvailable++;
      card!.qtdTickets--;
      this.removeTickets.emit({ id, qtd: card?.qtdTickets });
    }
  }
}
