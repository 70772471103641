import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Ticket } from 'src/app/models/ticket';
import { ModalComponentPagTesouro } from 'src/app/pages/buy-ticket/modal/modal.component';
import { TicketService } from 'src/app/services/ticket.service';
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TypeTicket } from 'src/app/models/typeTicket';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-buy-ticket',
  templateUrl: './buy-ticket.component.html',
  styleUrls: ['./buy-ticket.component.scss'],
})
export class BuyTicketComponent implements OnInit {
  isHiddenBtnTranslate: boolean = false;
  isModalActive: boolean = false;
  qtyVacanciesAvailable: number;
  msgBtn: string;
  spinnerIsActive: boolean = false;
  isError: boolean;
  isMsgErrorPay: boolean = true;
  linkUrlQrCode: string = '';
  isChecked: boolean = false;
  isCheckedTypeInput: boolean = false;
  isCheckedIfDatasTrue: boolean;
  registerForm: FormGroup;
  selectDate: string | null;
  isSelectedDay: boolean = false;
  isSelectedShift: boolean = false;
  step: number = 1;
  qtdTicketMilitary: number = 0;
  totalPrice: number = 0;
  quantitiesTickets: number = 0;
  tourists: number = 0;
  touristsCard: number = 0;
  touristRemove: number = 0;
  selectData: any;
  contStep: number;
  check1: string = '';
  check2: string = '';
  check3: string = '';
  check4: string = '';
  status: string = 'CRIADO';
  currentTicketId: string;
  listCountries: any[] = [];
  qrCodeSecondary: any[] = [];
  typeTicket: TypeTicket[] = [];
  ticket: Ticket = {
    is_foreign: false,
    id: undefined,
    operating_day_id: undefined,
    name: '',
    number_register: '',
    email: '',
    number_of_military: 0,
    number_of_children: 0,
    number_of_elderly: 0,
    number_of_tourist: 0,
    number_of_resident: 0,
    number_of_pcd_pne: 0,
    number_of_student: 0,
    tourist: [],
    total_value: 0,
    front_type: 'WEB_FRONT',
    shift_type: '',
    operating_day: undefined,
  };
  qrCodeMain: any = {};
  customerBtn: any = {
    'background-color': 'var(--color-blue-light)',
  };
  defineTop: any = { top: '127px' };

  selected_lang: any = localStorage.getItem('lang') || 'pt';

  msgErrorPay =
    this.selected_lang === 'pt'
      ? 'Não foi Possível realizar sua compra'
      : 'Unable to make your purchase';

  constructor(
    private datePipe: DatePipe,
    public dialog: MatDialog,
    private fb: FormBuilder,
    private ticketService: TicketService,
    private router: Router
  ) {}

  ngOnInit() {
    this.createRegisterForm();
    this.showTypeTicket();
    window.scrollTo(0, 0);
    this.replaceData();
  }

  createRegisterForm() {
    this.registerForm = this.fb.group({
      datas: this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        firstName: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(60),
          ],
        ],
        docIdentification: ['', Validators.required],
        tourists: this.fb.array([]),
      }),
    });
  }

  setDatasRegisterForm() {
    const ticket = this.ticket;
    const datasForms = this.registerForm.value.datas;
    ticket.email = datasForms.email;
    ticket.name = datasForms.firstName;
    ticket.number_register = datasForms.docIdentification;
    ticket.tourist = datasForms.tourists;
    ticket.is_foreign = this.isCheckedTypeInput;
    ticket.front_type = 'WEB_FRONT';

    this.creatTicket(ticket);
  }

  creatTicket(ticket: Ticket) {
    ticket.operating_day_id = ticket.operating_day
      ? ticket.operating_day
      : ticket.operating_day_id;

    this.ticketService.creatTicket(ticket).subscribe({
      next: (res) => {
        this.isError = false;
        this.ticket = res;
        this.currentTicketId = res.id as string;
        this.getTicketById(this.currentTicketId);
        this.step++;
        this.check2 = 'checked';
      },
      error: (error: HttpErrorResponse) => {
        this.isError = true;
        let errors =
          typeof error.error.message === 'object'
            ? error.error.message[0]
            : error.error.message;

        this.openModalHandlerError(errors);
      },
    });
  }

  createTicketValueDone() {
    this.ticketService
      .creatTicketValueDone(this.currentTicketId)
      .subscribe(() => {
        this.getStatusPaymentTicket();
      });
  }

  getTicketById(id: string) {
    this.ticketService.getTicketById(id).subscribe((res) => {
      this.ticket.id = res.id;
    });
  }

  payTicket() {
    this.ticketService.payTicket(this.currentTicketId).subscribe((res) => {
      const ticketUrl = res.paym_next_url as string;
      this.openModalPagTesouro(ticketUrl);
    });
  }

  getStatusPaymentTicket() {
    this.ticketService.getStatePayTicket(this.currentTicketId).subscribe({
      next: (res) => {
        const tickets = res;
        tickets.forEach((qrCode) => {
          if (qrCode.type === 'main') {
            this.qrCodeMain = qrCode;
          } else {
            this.qrCodeSecondary.push(qrCode);
          }
        });

        this.spinnerIsActive = true;
      },

      error: (error: HttpErrorResponse) => {
        console.log(error);
        this.spinnerIsActive = true;
      },
    });
  }

  openReceiptedTicket() {
    this.linkUrlQrCode =
      environment.apiURL + `/web-site/ticket/pdf/${this.currentTicketId}`;
  }

  checkStatusTicket() {
    this.ticketService
      .checkStatusTicket(this.currentTicketId)
      .subscribe((res) => {
        if (res.status === 'CONCLUIDO') {
          this.getStatusPaymentTicket();
        } else {
          this.spinnerIsActive = true;
          this.isMsgErrorPay = false;
          setTimeout(() => {
            this.router.navigate(['']);
          }, 3000);
        }
      });
  }

  checkPayment(id: string) {
    if (this.status != 'CONCLUIDO' && this.isModalActive === true) {
      setTimeout(() => {
        this.ticketService.checkStatusTicket(id).subscribe({
          next: (res) => {
            this.status = res.status;
          },
        });
        this.checkPayment(id);
      }, 3000);
    }
    if (this.status === 'CONCLUIDO') {
      this.dialog.closeAll();
      this.isModalActive = false;
    }
  }

  selectedDate(event: any) {
    let date = event;
    let dateToString = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.selectData = dateToString;
  }

  selectedShift(event: any) {
    this.ticket.operating_day_id = event.id;
    this.ticket.shift_type = event.shift;
    this.isSelectedShift = true;
  }

  handlerStateBtn(event: any) {
    this.isSelectedDay = event;
    this.isSelectedShift = false;
  }

  checkedDatas(event: boolean) {
    this.isChecked = event;
  }

  nextStep() {
    this.isHiddenBtnTranslate = true;
    if (this.step === 1) {
      if (this.isSelectedDay && this.isSelectedShift) {
        this.step++;
      }
      this.check1 = 'checked';
    } else if (this.step === 2) {
      if (this.isError === true) {
        return;
      }
    } else if (this.step === 3) {
      this.step++;
      if (this.ticket.total_value === 0) {
        this.createTicketValueDone();
      } else {
        this.payTicket();
      }
      this.check3 = 'checked';
    } else if (this.step === 4) {
      this.check3 = 'checked';
    }
  }

  previousStep() {
    this.contStep--;
    if (this.step === 1) {
      this.check1 = '';
    } else if (this.step === 3) {
      this.check3 = '';
      this.isCheckedIfDatasTrue = true;
    } else if (this.step === 4) {
      this.check4 = '';
    }
    this.step--;
  }

  getChangeQtd(event: any) {
    this.touristsCard = event;
  }

  setTickets(event: any) {
    const qtd = event.qtd;
    const ticket = this.ticket;

    if (event.id === 1) {
      ticket.number_of_tourist = qtd;
      this.tourists = qtd;
      this.touristsCard = qtd;
    } else if (event.id === 2) {
      ticket.number_of_resident = qtd;
    } else if (event.id === 3) {
      ticket.number_of_military = qtd;
    } else if (event.id === 4) {
      ticket.number_of_elderly = qtd;
    } else if (event.id === 5) {
      ticket.number_of_children = qtd;
    } else if (event.id === 6) {
      ticket.number_of_pcd_pne = qtd;
    } else if (event.id === 7) {
      ticket.number_of_student = qtd;
    }

    this.qtdTickets();
    this.valueTotalTicket();
  }

  removeTickets(event: any) {
    const qtd = event.qtd;
    const ticket = this.ticket;

    if (event.id === 1) {
      ticket.number_of_tourist = qtd;
      this.touristRemove = qtd;
      this.touristsCard = qtd;
    } else if (event.id === 2) {
      ticket.number_of_resident = qtd;
    } else if (event.id === 3) {
      ticket.number_of_military = qtd;
    } else if (event.id === 4) {
      ticket.number_of_elderly = qtd;
    } else if (event.id === 5) {
      ticket.number_of_children = qtd;
    } else if (event.id === 6) {
      ticket.number_of_pcd_pne = qtd;
    } else if (event.id === 7) {
      ticket.number_of_student = qtd;
    }
    this.qtdTickets();
    this.valueTotalTicket();
  }

  qtdTickets() {
    const qtd = this.typeTicket.map((data) => {
      return data.qtdTickets;
    });

    const sum = qtd.reduce((pre, acc) => {
      return pre + acc;
    }, 0);

    this.quantitiesTickets = sum;

    if (sum >= this.qtyVacanciesAvailable) {
      this.openModalExceptionVacancies();
    }
  }

  valueTotalTicket() {
    let selected_lang: any = localStorage.getItem('lang') || 'pt';
    let totalValue = this.typeTicket.reduce(getTotal, 0);

    function getTotal(total: number, item: TypeTicket) {
      return total + item.qtdTickets * item.ticket_value;
    }
    this.totalPrice = totalValue;

    if (this.totalPrice === 0) {
      this.msgBtn =
        selected_lang === 'pt' ? 'Ir Para Comprovante' : 'Go To Voucher';
    } else {
      this.msgBtn =
        selected_lang === 'pt' ? 'Efetuar Pagamento' : 'Make the payment';
    }
  }

  openModalPagTesouro(urlTicket: string) {
    let selected_lang: any = localStorage.getItem('lang') || 'pt';

    const dialog = this.dialog.open(ModalComponentPagTesouro, {
      width: '100%',
      height: '100%',
      disableClose: true,
      panelClass: 'customDialogPagTesouro',

      data: {
        url: urlTicket,
        currentTicketId: this.currentTicketId,
        textBtnYes: selected_lang === 'pt' ? 'Sim' : 'Yes',
        textBtnDont: selected_lang === 'pt' ? 'Não' : 'No',
      },
    });

    dialog.afterClosed().subscribe(() => {
      this.isModalActive = false;
      this.checkStatusTicket();
    });

    dialog.afterOpened().subscribe(() => {
      this.isModalActive = true;
      this.checkPayment(this.currentTicketId);
    });
  }

  openModalClearDatas() {
    let selected_lang: any = localStorage.getItem('lang') || 'pt';

    const dialog = this.dialog.open(DialogComponent, {
      data: {
        customerBtn: { background: '#FA5B79' },
        title: selected_lang === 'pt' ? 'Atenção!' : 'Heads up!',
        msg:
          selected_lang === 'pt'
            ? 'Seus dados serão perdidos, deseja Sair ?'
            : 'Your data will be lost, do you want to Exit?',
        textBtnYes: selected_lang === 'pt' ? 'Sim' : 'Yes',
        textBtnDont: selected_lang === 'pt' ? 'Não' : 'No',
        typeBuyTicket: 'buyTicket',
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result === true) {
        window.location.reload();
      }
    });
  }

  openModalHandlerError(errorMsg: string) {
    let selected_lang: any = localStorage.getItem('lang');

    const dialog = this.dialog.open(DialogComponent, {
      data: {
        title: selected_lang === 'pt' ? 'Atenção!' : 'Heads up!',
        msg: errorMsg,
        textBtnYes: 'Ok',
        isHidden: true,
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result === true) {
        dialog.close();
      }
    });
  }

  openModalExceptionVacancies() {
    let selected_lang: any = localStorage.getItem('lang') || 'pt';

    const dialog = this.dialog.open(DialogComponent, {
      disableClose: true,
      data: {
        disableClose: false,
        isHidden: true,
        customerBtn: { background: '#FA5B79' },
        title: selected_lang === 'pt' ? 'Atenção!' : 'Heads up!',
        msg:
          selected_lang === 'pt'
            ? 'Limite de vagas atingido!'
            : 'Maximum capacity reached!',
        textBtnYes: selected_lang === 'pt' ? 'fechar' : 'OK',
        typeBuyTicket: 'buyTicket',
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.dialog.closeAll();
      }
    });
  }

  showTypeTicket() {
    this.ticketService.getAlltypeTicket().subscribe((res) => {
      this.typeTicket = res;
      this.typeTicket.forEach((t) => (t.qtdTickets = 0));
    });
  }

  changeTypeInput(value: boolean) {
    this.isCheckedTypeInput = value;
  }

  replaceData() {
    this.registerForm.controls['datas']
      .get('firstName')
      ?.valueChanges.pipe(debounceTime(1))
      .subscribe((value: string) =>
        this.registerForm.controls['datas'].patchValue({
          firstName: value
            .replace(/(^\s)|(\d)|([^a-zA-Z 0-9 áéíóúçâêôãõ])/g, '')
            .replace('  ', ' '),
        })
      );
    this.registerForm.controls['datas']
      .get('docIdentification')
      ?.valueChanges.pipe(debounceTime(1))
      .subscribe((value: string) =>
        this.registerForm.controls['datas'].patchValue({
          docIdentification: value
            .replace(/(\s)|([\u0300-\u036f]|[^0-9a-zA-Z .-])/g, '')
            .replace('  ', ' '),
        })
      );
  }

  changeVacanciesAvailable(value: any) {
    if (value.shift === 'SHIFT_MORNING') {
      this.qtyVacanciesAvailable = value.value;
    }

    if (value.shift === 'SHIFT_AFTERNOON') {
      this.qtyVacanciesAvailable = value.value;
    }
  }
}
