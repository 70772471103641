<div #menuToogle [hidden]="isMenuOpen">
  <ul class="listItem" [ngStyle]="defineTop">
    <li class="item">
      <a href="#value" translate>appHeader.menu_values</a>
    </li>
    <li class="item">
      <a href="#hour" translate>appHeader.menu_opening_hours</a>
    </li>
    <li class="item"><a href="#adress" translate>appHeader.menu_address</a></li>
    <li class="item">
      <a href="#rules" translate>appHeader.menu_zoo_rules</a>
    </li>
  </ul>
</div>
