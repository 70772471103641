import { Component } from '@angular/core';
interface Languages {
    id: number;
    text: string;
}
@Component({
    selector: 'app-menu-translate',
    templateUrl: './menu-translate.component.html',
    styleUrls: ['./menu-translate.component.scss'],
})
export class MenuTranslateComponent {
    languages: Languages[] = [
        { id: 1, text: 'Português' },
        { id: 2, text: 'English' },
    ];

    languageSelect: string = 'Português';
}
