import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { addDays } from 'date-fns';
import { pluck } from 'rxjs';
import { Scheduling } from 'src/app/models/scheduling';
import { SchedulingService } from 'src/app/services/scheduling.service';

export class DatepickerCustomHeader extends MomentDateAdapter {
  override getFirstDayOfWeek(): number {
    return 0;
  }
}

@Component({
  selector: 'app-calendary',
  templateUrl: './calendary.component.html',
  styleUrls: ['./calendary.component.scss'],

  providers: [
    {
      provide: MAT_DATE_LOCALE,
      useValue: localStorage.getItem('lang') || 'pt',
    },

    {
      provide: DateAdapter,
      useClass: DatepickerCustomHeader,
      deps: [MAT_DATE_LOCALE],
    },
  ],
})
export class CalendaryComponent implements OnInit {
  @Output() selectedChange: EventEmitter<Date> = new EventEmitter<Date>();
  currentDate: Date = new Date();
  currentDateMin: Date;
  currentDateToString: string;
  maxDate: Date;
  selected: any;
  listDaysOperating: Date[] = [];
  constructor(
    private schedulingService: SchedulingService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.currentDateToString = this.transformDate(this.currentDate) as string;
    this.showOperationDay();
  }

  getChangedValue(date: Date | null) {
    this.selectedChange.emit(date as Date);
  }

  showOperationDay() {
    this.schedulingService.getAllOperationDayActive().subscribe({
      next: (res) => {
        let response = res;
        let minDate = res[0].day;
        this.currentDateMin = minDate as Date;
        const result = response.map((item) => {
          return this.listDaysOperating.push(
            new Date(
              addDays(new Date(String(item.day)).setHours(0, 0, 0, 0), 1)
            )
          );
        });
      },
    });
  }

  disabledDaysFilter = (date: Date): boolean => {
    const time = new Date(date).getTime();
    const result = !this.listDaysOperating.find(
      (item) => item.getTime() === time
    );
    return !result;
  };

  transformDate(date: Date | any) {
    const dateTransform = this.datePipe.transform(date, 'YYYY-MM-dd');
    return dateTransform;
  }
}
