import { Agent } from './agent';
import { People } from './people';

export class TicketInstitution {
  id?: number;
  institution_type: number;
  institution_name: string;
  institution_visit_date: string;
  institution_visit_time: string;
  people: People = new People();
  agent: Agent = new Agent();
}
