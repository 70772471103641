import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { State } from '../models/state';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  readonly BASE_URL_COUNTRY: string = environment.apiCountry;
  readonly BASE_URL_STATE: string = environment.apiState;

  constructor(private http: HttpClient) {}

  getAllCountries() {
    const url = '/all';
    return this.http.get<any[]>(this.BASE_URL_COUNTRY + url);
  }

  getAllStateBr() {
    const url: string = '/estados?orderBy=nome';
    return this.http.get<State[]>(this.BASE_URL_STATE + url);
  }
}
