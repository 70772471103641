<div class="cardModalPay">
    <div class="closeModal">
        <i
            (click)="openModalCancel()"
            class="fa-solid fa-xmark fa-2xl"
            aria-hidden="true"
        ></i>
    </div>

    <div mat-dialog-content class="contentModalPay">
        <p class="description"></p>
        <app-pag-tesouro [url]="data.url"></app-pag-tesouro>
    </div>
    <div mat-dialog-actions *ngIf="!teste">
        <div class="cardBtn"></div>
    </div>
    <div mat-dialog-actions *ngIf="teste">
        <div class="cardBtn">
            <app-button
                (click)="closeModal()"
                [customerBtn]="customerBtn"
                text="Ir para Comprovante"
                [isHidden]="true"
            ></app-button>
        </div>
    </div>
</div>
