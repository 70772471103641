<div
  [ngClass]="[
    isMonday === true ? 'columnReverse' : 'container containerContent'
  ]"
>
  <app-calendary (selectedChange)="selectedDate($event)"></app-calendary>

  <div class="cardHours" *ngIf="!isMonday">
    <div class="cardHeader">
      <h3 class="title" translate>buyTicket.scheduling.hour_title</h3>

      <h4 class="subtitle" translate>buyTicket.scheduling.hour_description</h4>
    </div>

    <mat-card class="container containerMain">
      <div class="cardMainDays">
        <button
          [disabled]="isDisabledMorning"
          (click)="selectShiftScheduling(schedulings.id, 'SHIFT_MORNING')"
          type="button"
          class="btn btnCustome"
          [ngClass]="isShiftSelectedMorning ? 'active' : 'btnCustome'"
        >
          <div class="btnCustome__vagas" translate>
            buyTicket.scheduling.hour_morning
          </div>
        </button>

        <button
          [disabled]="isDisabledAfternoon"
          (click)="selectShiftScheduling(schedulings.id, 'SHIFT_AFTERNOON')"
          type="button"
          class="btn btnCustome"
          [ngClass]="isShiftSelectedAfternoon ? 'active' : 'btnCustome'"
        >
          <div class="btnCustome__vagas" translate>
            buyTicket.scheduling.hour_afternoon
          </div>
        </button>
      </div>

      <div class="cardDesc">
        <p class="text">
          {{ msg }}
        </p>
      </div>
    </mat-card>
  </div>

  <div class="cardMainInfo" *ngIf="isMonday">
    <app-info-mgs
      msg="  Não realizamos venda de ingressos as segundas. Selecione um dia válido"
    ></app-info-mgs>
  </div>
</div>
