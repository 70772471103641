<div class="container p-0">
    <div>
        <div class="cardHeader">
            <h3 class="title" translate>
                buyTicket.identification.summary_title
            </h3>
        </div>

        <div class="cardContent">
            <mat-card class="cardMain">
                <div class="headerContent">
                    <div class="headerContent__ticket">
                        <h3 class="title" translate>
                            buyTicket.identification.ticket_column
                        </h3>
                    </div>

                    <div class="headerContent__quantitie">
                        <h3 class="title" translate>
                            buyTicket.identification.qty_column
                        </h3>
                    </div>

                    <div class="headerContent__value">
                        <h3 class="title" translate>
                            buyTicket.identification.price_column
                        </h3>
                    </div>
                </div>

                <div
                    *ngFor="let dataAbs of dataAbstract"
                    class="headerContent content"
                >
                    <div class="headerContent__typeTicket">
                        <p class="blueLight">{{ dataAbs.ticket_name }}</p>
                    </div>

                    <div class="headerContent__quantitie">
                        <p class="headerContent__quantitie margin-left">
                            {{ dataAbs.qtdTickets }}
                        </p>
                    </div>

                    <div class="headerContent__value">
                        <p class="blueLight">R$ {{ dataAbs.ticket_value }}</p>
                    </div>
                </div>

                <div class="headerContent content mt-2">
                    <div class="cardMainTotal">
                        <div
                            class="container"
                            style="display: flex; align-items: center"
                        >
                            <div class="headerContent__typeTicket">
                                <h3 class="m-0 bold" translate>
                                    buyTicket.identification.total
                                </h3>
                            </div>

                            <div class="headerContent__quantitie">
                                <h3
                                    class="headerContent__quantitie modify bold"
                                >
                                    {{ qtdTotalTickets }}
                                </h3>
                            </div>

                            <div class="headerContent__value">
                                <h3 class="m-0 bold">R$ {{ totalPrice }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
    </div>
</div>
