import { Component, Input } from '@angular/core';
import { TypeTicket } from 'src/app/models/typeTicket';

@Component({
  selector: 'app-abstract',
  templateUrl: './abstract.component.html',
  styleUrls: ['./abstract.component.scss'],
})
export class AbstractComponent {
  @Input() title: string = 'Resumo';
  @Input() qtdTotalTickets: number = 0;
  @Input() totalPrice: number = 0;
  @Input() dataAbstract: TypeTicket[] = [];
}
