<div class="container" style="margin-top: 50px; margin-bottom: 160px">
  <div class="cardTitle">
    <h3 class="title" translate>buyTicket.identification.visitor_data_title</h3>
  </div>

  <form [formGroup]="registerForm">
    <mat-card class="cardForms">
      <div class="cardInput">
        <h3 class="span" translate>buyTicket.identification.visitor_email</h3>

        <mat-form-field appearance="outline">
          <input
            [errorStateMatcher]="matcher"
            matInput
            placeholder="{{
              'buyTicket.identification.visitor_email_placeholder' | translate
            }}"
            formControlName="email"
          />

          <mat-error translate *ngIf="!email?.hasError('required')">
            global.invalid_email
          </mat-error>
          <mat-error *ngIf="email?.hasError('required')" translate
            >global.required_field</mat-error
          >
        </mat-form-field>
      </div>

      <div class="cardInput firstName">
        <h3 class="span" translate>buyTicket.identification.visitor_name</h3>

        <mat-form-field appearance="outline">
          <input
            maxlength="60"
            #inputFirstName
            matInput
            placeholder="{{
              'buyTicket.identification.visitor_name_placeholder' | translate
            }}"
            formControlName="firstName"
          />

          <mat-hint align="end"
            >{{ inputFirstName.value.length }} / 60</mat-hint
          >

          <mat-error translate *ngIf="firstName?.hasError('required')">
            global.required_field
          </mat-error>
          <mat-error *ngIf="!firstName?.hasError('required')" translate>
            buyTicket.identification.visitor_name_warn
          </mat-error>
        </mat-form-field>
      </div>

      <div class="cardCpfBday">
        <div *ngIf="!typeInput" class="cardInput identification">
          <h3 class="span" translate>buyTicket.identification.visitor_cpf</h3>

          <mat-form-field appearance="outline">
            <input
              [dropSpecialCharacters]="false"
              [mask]="maskCpf"
              matInput
              placeholder="000.000.000-00"
              formControlName="docIdentification"
            />

            <mat-error *ngIf="docIdentification?.hasError('required')" translate
              >global.required_field
            </mat-error>

            <mat-error *ngIf="!docIdentification?.hasError('required')"
              >Campo inválido
            </mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="typeInput" class="cardInput identification">
          <h3 class="span" translate>
            buyTicket.identification.visitor_passport
          </h3>

          <mat-form-field appearance="outline">
            <input
              minlength="7"
              maxlength="10"
              matInput
              placeholder="Enter the passport"
              formControlName="docIdentification"
            />
            <mat-error translate>global.required_field</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="cardInput">
        <div class="cardInput">
          <section class="example-section">
            <mat-checkbox
              (change)="changeTypeInput($event)"
              class="example-margin"
              translate
            >
              <span translate>
                buyTicket.identification.visitor_data_form_check_1
              </span>
            </mat-checkbox>
          </section>
        </div>

        <section class="mt-3">
          <mat-checkbox
            [checked]="isChecked"
            (change)="changeConfirmDatas($event)"
          >
            <span translate>
              buyTicket.identification.visitor_data_form_check_2
            </span>
          </mat-checkbox>
        </section>
      </div>
    </mat-card>

    <div *ngIf="tourists.length != 0">
      <div class="cardTitle mt-4">
        <h3 class="title" translate>
          buyTicket.identification.tourist_data_title
        </h3>
      </div>

      <mat-card class="cardForms">
        <div
          formArrayName="tourists"
          *ngFor="let tou of tourists.controls; index as indexOff"
        >
          <ng-container [formGroupName]="indexOff">
            <div class="cardInput selectCountry">
              <h3 class="span">
                {{ "buyTicket.identification.tourist_title" | translate }}

                {{ indexOff + 1 }}
              </h3>

              <div class="cardMainLocationsTourist">
                <div class="cardMainLocationsTourist__country">
                  <h3 class="span m-0" translate>
                    buyTicket.identification.country_label
                  </h3>

                  <mat-form-field style="width: 100%" appearance="outline">
                    <mat-select
                      #selectCountry
                      formControlName="country"
                      placeholder="{{
                        'buyTicket.identification.country_placeholder'
                          | translate
                      }}"
                    >
                      <mat-option
                        [value]="country"
                        *ngFor="let country of listCountries"
                        >{{ country }}</mat-option
                      >
                    </mat-select>

                    <mat-error translate>global.required_field </mat-error>
                  </mat-form-field>
                </div>

                <div
                  class="cardMainLocationsTourist__country"
                  *ngIf="
                    selectCountry?.value === 'Brasil' ||
                    selectCountry?.value === 'Brazil'
                  "
                >
                  <h3 class="span m-0" translate>
                    buyTicket.identification.state_label
                  </h3>

                  <mat-form-field style="width: 100%" appearance="outline">
                    <mat-select
                      placeholder="{{
                        'buyTicket.identification.state_placeholder' | translate
                      }}"
                      formControlName="state"
                      [required]="true"
                    >
                      <mat-option
                        *ngFor="let listStateBr of listStatesBrazil"
                        [value]="listStateBr.sigla"
                        >{{ listStateBr.nome }}</mat-option
                      >
                    </mat-select>

                    <mat-error translate>global.required_field </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </mat-card>
    </div>
  </form>
</div>
