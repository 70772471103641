import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PayPagtesouro } from '../models/payPagtosuro';
import { Ticket } from '../models/ticket';
import { TypeTicket } from '../models/typeTicket';

@Injectable({
  providedIn: 'root',
})
export class TicketService {
  BASE_URL = environment.apiURL;

  constructor(private http: HttpClient) {}

  creatTicket(ticket: Ticket) {
    let lang = localStorage.getItem('lang') || 'pt';

    const url = `/web-site/ticket/create?lang=${lang}`;
    return this.http.post<Ticket>(this.BASE_URL + url, ticket);
  }

  creatTicketValueDone(id: string) {
    const url = '/web-site/ticket/done';
    return this.http.post<any>(this.BASE_URL + url, {
      ticket_id: id,
    });
  }

  getTicketById(id: string) {
    const url = `/web-site/ticket/find/${id}`;
    return this.http.get<Ticket>(this.BASE_URL + url);
  }

  payTicket(id: string) {
    const url = '/web-site/ticket/pay';
    return this.http.post<Ticket>(this.BASE_URL + url, {
      ticket_id: id,
    });
  }

  checkStatusTicket(id: string): Observable<PayPagtesouro> {
    const url = `/web-site/ticket/pay/${id}`;
    return this.http.get<PayPagtesouro>(this.BASE_URL + url);
  }

  getStatePayTicket(id: string) {
    let lang = localStorage.getItem('lang') || 'pt';

    const url = `/web-site/ticket/find_all_codes/${id}?lang=${lang}`;
    return this.http.get<any[]>(this.BASE_URL + url);
  }

  getAlltypeTicket(): Observable<TypeTicket[]> {
    let lang = localStorage.getItem('lang') || 'pt';

    const url = `/ticket/tipos/all?lang=${lang}`;
    return this.http.get<TypeTicket[]>(this.BASE_URL + url);
  }
}
