<div class="container-fluid">
    <header>
        <h3 class="title" translate>
            buyTicketInstitution.confirmation.section_title_confirmation
        </h3>
    </header>

    <div class="container">
        <mat-card class="cardForm">
            <div class="cardMainRepresentative">
                <div class="cardMainRepresentative__title">
                    <h3 class="cardMainRepresentative__title--title" translate>
                        buyTicketInstitution.confirmation.section_visitor_data
                    </h3>
                </div>

                <div class="cardMainDatas">
                    <div class="cardMainHeader">
                        <div class="cardMainHeader__header">
                            <h3 class="title" translate>
                                buyTicketInstitution.confirmation.label_type_institution
                            </h3>

                            <p class="sub">
                                {{
                                    setTypeOrganizationName(
                                        datasVisitRequest?.institution_type
                                    )
                                }}
                            </p>

                            <p class="sub">
                                {{ typeInstitution }}
                            </p>
                        </div>

                        <div>
                            <h3 class="title" translate>
                                buyTicketInstitution.confirmation.label_institution_name
                            </h3>

                            <p class="sub">
                                {{ datasVisitRequest?.institution_name }}
                            </p>
                        </div>
                    </div>

                    <div class="cardMainHeader">
                        <div class="cardMainHeader__header">
                            <h3 class="title">Data da visita</h3>
                            <p class="sub">
                                {{
                                    transformDate(
                                        datasVisitRequest?.institution_visit_date
                                    )
                                }}
                            </p>
                        </div>

                        <div>
                            <h3 class="title" translate>
                                buyTicketInstitution.confirmation.label_visiting_hours
                            </h3>

                            <p class="sub">
                                {{ datasVisitRequest?.institution_visit_time }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="cardTypes">
                    <p class="text" translate>
                        buyTicketInstitution.confirmation.title_qty_students
                    </p>

                    <hr class="lineHorizontal" />
                </div>

                <div class="cardMainDatas">
                    <div class="cardMainHeader">
                        <div class="cardMainHeader__header">
                            <h3 class="title" translate>
                                buyTicketInstitution.confirmation.label_qty_students_1
                            </h3>

                            <p class="sub">
                                {{ datasVisitRequest?.people?.until_12 }}
                            </p>
                        </div>

                        <div>
                            <h3 class="title" translate>
                                buyTicketInstitution.confirmation.label_qty_students_2
                            </h3>

                            <p class="sub">
                                {{ datasVisitRequest?.people?.above_12 }}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="cardTypes">
                    <p class="text" translate>
                        buyTicketInstitution.confirmation.title_qty_adults
                    </p>

                    <hr class="lineHorizontal" />
                </div>

                <div class="cardMainDatas">
                    <div class="cardMainHeader">
                        <div class="cardMainHeader__header">
                            <h3 class="title" translate>
                                buyTicketInstitution.confirmation.label_qty_adults_1
                            </h3>

                            <p class="sub">
                                {{ datasVisitRequest?.people?.adults }}
                            </p>
                        </div>

                        <div>
                            <h3 class="title" translate>
                                buyTicketInstitution.confirmation.label_qty_adults_2
                            </h3>

                            <p class="sub">
                                {{ datasVisitRequest?.people?.elderlies }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="cardTypes">
                    <p class="text" translate>
                        buyTicketInstitution.confirmation.title_qty_pwd
                    </p>

                    <hr class="lineHorizontal" />
                </div>

                <div class="cardMainDatas">
                    <div class="cardMainHeader">
                        <div class="cardMainHeader__header">
                            <h3 class="title" translate>
                                buyTicketInstitution.confirmation.label_qty_pwd
                            </h3>

                            <p class="sub">
                                {{ datasVisitRequest?.people?.pcds }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="cardMainRepresentative__title mt-4">
                    <h3 class="cardMainRepresentative__title--title">
                        Dados do Representante
                    </h3>
                </div>

                <div class="cardMainDatas">
                    <div class="cardMainHeader">
                        <div class="cardMainHeader__header">
                            <h3 class="title" translate>
                                buyTicketInstitution.confirmation.label_representative_name
                            </h3>

                            <p class="sub">
                                {{ datasVisitRequest?.agent?.name }}
                            </p>
                        </div>
                    </div>

                    <div class="cardDateAndHour">
                        <div class="cardDateAndHour__date">
                            <h3 class="title" translate>
                                buyTicketInstitution.confirmation.label_function
                            </h3>

                            <p class="sub">
                                {{ datasVisitRequest?.agent?.post }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="cardMainDatas">
                    <div class="cardMainHeader">
                        <div class="cardMainHeader__header">
                            <h3 class="title" translate>
                                buyTicketInstitution.confirmation.label_email
                            </h3>

                            <p class="sub">
                                {{ datasVisitRequest?.agent?.email }}
                            </p>
                        </div>

                        <div>
                            <h3 class="title" translate>
                                buyTicketInstitution.confirmation.label_contact_phone
                            </h3>

                            <p class="sub">
                                {{ datasVisitRequest?.agent?.phone_number }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </mat-card>
    </div>
</div>
