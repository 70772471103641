<div class="content" [id]="rules">
  <div class="cardRules">
    <h2 class="title" translate>homePage.zoo_rules.title</h2>
  </div>

  <div class="cardListRules">
    <div class="cardContentRules">
      <div class="cardLocation">
        <div class="caret">
          <i class="fa-solid fa-caret-right fa-xl icon" aria-hidden="true"></i>
        </div>

        <p class="text" translate>homePage.zoo_rules.rule_3</p>
      </div>
      <div class="cardLocation">
        <div class="caret">
          <i class="fa-solid fa-caret-right fa-xl icon" aria-hidden="true"></i>
        </div>
        <p class="text" translate>homePage.zoo_rules.rule_4</p>
      </div>
      <div class="cardLocation">
        <div class="caret">
          <i class="fa-solid fa-caret-right fa-xl icon" aria-hidden="true"></i>
        </div>
        <p class="text" translate>homePage.zoo_rules.rule_5</p>
      </div>
      <div class="cardLocation">
        <div class="caret">
          <i class="fa-solid fa-caret-right fa-xl icon" aria-hidden="true"></i>
        </div>

        <p class="text" translate>homePage.zoo_rules.rule_6</p>
      </div>
    </div>
  </div>
</div>
