import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

import { TypeInstitution } from 'src/app/models/type-institution';
import { InstitutionService } from 'src/app/services/institution.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

export interface ConfigPeople {
  qtd: number;
}
export interface VisitingHour {
  id: number;
  hour: string;
}

@Component({
  selector: 'app-visit-request',
  templateUrl: './visit-request.component.html',
  styleUrls: ['./visit-request.component.scss'],
})
export class VisitRequestComponent implements OnInit {
  mb: any = {
    'margin-bottom': '30px',
  };
  currentDate: Date;
  @Input() formVisitRequest: FormGroup;
  @Input() formGroupName: string;
  @Output() valuesTypePeople: EventEmitter<any> = new EventEmitter();
  @Output() listHours: EventEmitter<any> = new EventEmitter();
  matcher = new MyErrorStateMatcher();

  listStudentsUntil12: ConfigPeople[] = [];
  listStudentsAbove12: ConfigPeople[] = [];
  listElderlies: ConfigPeople[] = [];
  listAdults: ConfigPeople[] = [];
  listPcds: ConfigPeople[] = [];

  typeInstitutions: TypeInstitution[] = [];
  @Input() visitingHours: any[] = [];
  qtyStudentsAbove12: number = 0;
  qtyStudentsUntil12: number = 0;
  qtyElderlies: number = 0;
  qtyAldults: number = 0;
  typePeople: 'above12' | 'until12' | 'adults' | 'elderlies' | 'pcds';
  selectDate: string;
  valueDefault: number = 0;
  constructor(
    private rootFormGroup: FormGroupDirective,
    private institutionService: InstitutionService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    this.rangeListQtd();
    this.createFormVisitRequest();
    this.listAllInstitutionType();
    this.filterDisabledDays();
  }

  rangeListQtd() {
    for (let index = 1; index <= 200 - this.qtyStudentsAbove12; index++) {
      this.listStudentsUntil12.push({ qtd: index });
    }
    for (let index = 1; index <= 200 - this.qtyStudentsUntil12; index++) {
      this.listStudentsAbove12.push({ qtd: index });
    }

    for (let index = 1; index <= 20 - this.qtyAldults; index++) {
      this.listElderlies.push({ qtd: index });
    }

    for (let index = 1; index <= 20 - this.qtyElderlies; index++) {
      this.listAdults.push({ qtd: index });
    }
    for (let index = 1; index <= 20; index++) {
      this.listPcds.push({ qtd: index });
    }
  }

  changeValueTypePeople(value: number, type = this.typePeople) {
    this.valuesTypePeople.emit({ value: value, type: type });

    if (type === 'until12') {
      this.listStudentsAbove12 = [];
      this.listStudentsUntil12 = [];
      this.listPcds = [];
      this.listElderlies = [];
      this.listAdults = [];
      this.qtyStudentsUntil12 = value;
      this.rangeListQtd();
    }
    if (type === 'above12') {
      this.listStudentsUntil12 = [];
      this.listStudentsAbove12 = [];
      this.listPcds = [];
      this.listElderlies = [];
      this.listAdults = [];
      this.qtyStudentsAbove12 = value;
      this.rangeListQtd();
    }
    if (type === 'adults') {
      this.listElderlies = [];
      this.listAdults = [];
      this.listPcds = [];
      this.listStudentsAbove12 = [];
      this.listStudentsUntil12 = [];
      this.qtyAldults = value;
      this.rangeListQtd();
    }
    if (type === 'elderlies') {
      this.listAdults = [];
      this.listElderlies = [];
      this.listPcds = [];
      this.listStudentsAbove12 = [];
      this.listStudentsUntil12 = [];
      this.qtyElderlies = value;
      this.rangeListQtd();
    }
  }

  createFormVisitRequest() {
    this.formVisitRequest = this.rootFormGroup.control.get(
      this.formGroupName
    ) as FormGroup;
  }

  listAllInstitutionType() {
    this.institutionService
      .getAllInstitutionTypes()
      .subscribe({ next: (res) => (this.typeInstitutions = res) });
  }

  filterDisabledDays() {
    const today = new Date();
    today.setDate(today.getDate() + 8);
    this.currentDate = today;
  }

  valuesChangeDate(value: MatDatepickerInputEvent<Date>) {
    const date = value.value;
    const dateToString = this.datePipe.transform(date, 'YYYY-MM-dd') as string;

    this.changeAvailableHour(dateToString);
  }

  changeAvailableHour(value: string) {
    this.institutionService.getAvailableHour(value).subscribe({
      next: (res) => {
        this.listHours.emit(res);
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
      },
    });
  }

  get institutionName() {
    return this.formVisitRequest.get('institution_name');
  }
}
