<div class="container-fluid">
    <header>
        <h3 class="title" translate>
            buyTicketInstitution.visit_request.form_title
        </h3>
    </header>

    <div class="container">
        <form [formGroup]="formVisitRequest">
            <mat-card class="cardForm">
                <h3 class="span m-0" translate>
                    buyTicketInstitution.visit_request.label_institution_type
                </h3>

                <mat-form-field style="width: 100%" appearance="outline">
                    <mat-select
                        placeholder="{{
                            'buyTicketInstitution.visit_request.placeholder_institution_type'
                                | translate
                        }}"
                        formControlName="institution_type"
                    >
                        <mat-option
                            *ngFor="let typeInstitution of typeInstitutions"
                            [value]="typeInstitution.id"
                            >{{ typeInstitution.name }}
                        </mat-option>
                    </mat-select>

                    <mat-error translate>global.required_field</mat-error>
                </mat-form-field>

                <h3 class="span m-0" translate>
                    buyTicketInstitution.visit_request.label_institution_name
                </h3>

                <mat-form-field appearance="outline" style="width: 100%">
                    <input
                        [errorStateMatcher]="matcher"
                        formControlName="institution_name"
                        #nameInstitution
                        placeholder="{{
                            'buyTicketInstitution.visit_request.placeholder_institution_name'
                                | translate
                        }}"
                        matInput
                        minlength="20"
                        maxlength="50"
                    />

                    <mat-error
                        *ngIf="!institutionName?.hasError('required')"
                        translate
                    >
                        buyTicketInstitution.visit_request.error_institution_name
                    </mat-error>

                    <mat-error
                        *ngIf="institutionName?.hasError('required')"
                        translate
                    >
                        global.required_field
                    </mat-error>
                </mat-form-field>
                <mat-hint class="contCaracters" align="end"
                    >{{ nameInstitution.value?.length || 0 }}/50</mat-hint
                >

                <div class="cardDateScheduling mt-2">
                    <div class="typeInput">
                        <h3 class="span m-0" translate>
                            buyTicketInstitution.visit_request.label_visit_date
                        </h3>

                        <mat-form-field
                            class="field inputDateVisit"
                            appearance="outline"
                        >
                            <input
                                (click)="picker.open()"
                                readonly
                                matInput
                                [matDatepicker]="picker"
                                formControlName="institution_visit_date"
                                placeholder="{{
                                    'buyTicketInstitution.visit_request.placeholder_visit_date'
                                        | translate
                                }}"
                                [min]="currentDate"
                                (dateChange)="valuesChangeDate($event)"
                            />

                            <mat-datepicker-toggle
                                matSuffix
                                [for]="picker"
                            ></mat-datepicker-toggle>

                            <mat-datepicker #picker></mat-datepicker>

                            <mat-error translate>
                                buyTicketInstitution.visit_request.error_visit_date
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="typeInput">
                        <h3 class="span m-0" translate>
                            buyTicketInstitution.visit_request.label_visiting_hours
                        </h3>

                        <mat-form-field appearance="outline" class="field">
                            <mat-select
                                formControlName="institution_visit_time"
                                placeholder="{{
                                    'buyTicketInstitution.visit_request.placeholder_visiting_hours'
                                        | translate
                                }}"
                            >
                                <mat-option
                                    *ngFor="let visitingHour of visitingHours"
                                    [value]="visitingHour"
                                    >{{ visitingHour }}</mat-option
                                >
                            </mat-select>

                            <mat-error translate>
                                global.required_field
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>

                <ng-container formGroupName="people">
                    <div class="cardTypesTicket mt-2">
                        <div class="cardInput">
                            <h3 class="span m-0" translate>
                                buyTicketInstitution.visit_request.label_qty_students
                            </h3>

                            <mat-form-field class="field" appearance="outline">
                                <mat-select
                                    formControlName="until_12"
                                    placeholder="{{
                                        'buyTicketInstitution.visit_request.placeholder_qty_students_1'
                                            | translate
                                    }}"
                                    panelClass="example-panel-red"
                                    (valueChange)="
                                        changeValueTypePeople($event, 'until12')
                                    "
                                >
                                    <mat-option value="0">
                                        {{ "global.no_option" | translate }}
                                    </mat-option>

                                    <mat-option
                                        *ngFor="let qtd of listStudentsUntil12"
                                        [value]="qtd.qtd"
                                    >
                                        {{ qtd.qtd }}
                                    </mat-option>
                                </mat-select>

                                <mat-error translate>
                                    global.required_field
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="field" appearance="outline">
                                <mat-select
                                    formControlName="above_12"
                                    placeholder="{{
                                        'buyTicketInstitution.visit_request.placeholder_qty_students_2'
                                            | translate
                                    }}"
                                    (valueChange)="
                                        changeValueTypePeople($event, 'above12')
                                    "
                                >
                                    <mat-option value="0">
                                        {{ "global.no_option" | translate }}
                                    </mat-option>

                                    <mat-option
                                        *ngFor="let qtd of listStudentsAbove12"
                                        [value]="qtd.qtd"
                                    >
                                        {{ qtd.qtd }}
                                    </mat-option>
                                </mat-select>

                                <mat-error translate>
                                    global.required_field
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="cardInput">
                            <h3 class="span m-0" translate>
                                buyTicketInstitution.visit_request.label_qty_adults
                            </h3>

                            <mat-form-field class="field" appearance="outline">
                                <mat-select
                                    formControlName="adults"
                                    placeholder="{{
                                        'buyTicketInstitution.visit_request.placeholder_qty_adults_1'
                                            | translate
                                    }}"
                                    (valueChange)="
                                        changeValueTypePeople($event, 'adults')
                                    "
                                >
                                    <mat-option value="0">
                                        {{ "global.no_option" | translate }}
                                    </mat-option>

                                    <mat-option
                                        *ngFor="let qtd of listAdults"
                                        [value]="qtd.qtd"
                                    >
                                        {{ qtd.qtd }}
                                    </mat-option>
                                </mat-select>

                                <mat-error translate>
                                    global.required_field
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field class="field" appearance="outline">
                                <mat-select
                                    formControlName="elderlies"
                                    placeholder="{{
                                        'buyTicketInstitution.visit_request.placeholder_qty_adults_2'
                                            | translate
                                    }}"
                                    (valueChange)="
                                        changeValueTypePeople(
                                            $event,
                                            'elderlies'
                                        )
                                    "
                                >
                                    <mat-option value="0">
                                        {{ "global.no_option" | translate }}
                                    </mat-option>

                                    <mat-option
                                        *ngFor="let qtd of listElderlies"
                                        [value]="qtd.qtd"
                                    >
                                        {{ qtd.qtd }}
                                    </mat-option>
                                </mat-select>

                                <mat-error translate>
                                    global.required_field
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="cardInput">
                            <h3 class="span m-0" translate>
                                buyTicketInstitution.visit_request.label_qty_pwd
                            </h3>

                            <mat-form-field class="field" appearance="outline">
                                <mat-select
                                    formControlName="pcds"
                                    placeholder="{{
                                        'buyTicketInstitution.visit_request.placeholder_qty_pwd'
                                            | translate
                                    }}"
                                    (valueChange)="
                                        changeValueTypePeople($event, 'pcds')
                                    "
                                >
                                    <mat-option value="0">
                                        {{ "global.no_option" | translate }}
                                    </mat-option>

                                    <mat-option
                                        *ngFor="let qtd of listPcds"
                                        [value]="qtd.qtd"
                                    >
                                        {{ qtd.qtd }}
                                    </mat-option>
                                </mat-select>

                                <mat-error translate>
                                    global.required_field
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </ng-container>
            </mat-card>
        </form>
    </div>
</div>
