import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-rules-zoo',
  templateUrl: './rules-zoo.component.html',
  styleUrls: ['./rules-zoo.component.scss'],
})
export class RulesZooComponent {
  @Input() rules: string;
}
