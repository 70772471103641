import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
    @Input() msg: string;
    @Input() title: string;
    @Input() isHiddenIcon: boolean = false;
    @Input() widthBtn: any = {
        width: '80px',
    };

    @Input() paddingMsg: any = {
        padding: '',
    };
    @Input() customerBtn: any = {
        background: '#FA5B79',
    };
    @Input() typeBuyTicket: string = '';
    isHidden: boolean = false;
    textBtnYes: string;
    textBtnDont: string;

    constructor(
        @Inject(MAT_DIALOG_DATA)
        public data: {
            title: string;
            msg: string;
            isHidden: boolean;
            textBtnYes: string;
            textBtnDont: string;
            isHiddenIcon: boolean;
            widthBtn: any;
            customerBtn: any;
            paddingMsg: any;
            typeBuyTicket: string;
        },
        public dialogRef: MatDialogRef<DialogComponent>
    ) {}

    ngOnInit() {
        this.msg = this.data.msg;
        this.isHidden = this.data.isHidden;
        this.textBtnYes = this.data.textBtnYes;
        this.textBtnDont = this.data.textBtnDont;
        this.title = this.data.title;
        this.isHiddenIcon = this.data.isHiddenIcon;
        this.widthBtn = this.data.widthBtn;
        this.customerBtn = this.data.customerBtn;
        this.paddingMsg = this.data.paddingMsg;
        this.typeBuyTicket = this.data.typeBuyTicket;
    }
}
