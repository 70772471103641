<div class="content">
  <div class="cardTitle">
    <h2 class="title" translate>homePage.institution_visit.title</h2>
  </div>

  <p class="text" translate>homePage.institution_visit.description</p>

  <div class="content">
    <div class="cardMainBtn btnInstitution">
      <app-button
        icon="local_activity"
        text="{{ 'homePage.institution_visit.request_visit' | translate }}"
        link="/buyTicketInstitution"
      ></app-button>
    </div>
  </div>
</div>
