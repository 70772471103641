<div class="contentMain">
  <app-header
    [isHiddenBtnTranslate]="isHiddenBtnTranslate"
    [defineTop]="defineTop"
  ></app-header>

  <div class="test">
    <app-header-menu
      [isHiddenBtnInstitution]="true"
      [isHiddenBtn]="true"
    ></app-header-menu>
  </div>
</div>

<div class="container-fluid containerMainStepper">
  <div class="container cardMainSteps">
    <div class="pointerOne" [ngClass]="step === 1 ? 'active' : check1">
      <div class="content">
        <h1 class="title">1</h1>
        <h3 class="subtitle" translate>buyTicket.step_scheduling</h3>
      </div>
    </div>

    <div
      class="pointerMidlle"
      id="step2"
      [ngClass]="step === 2 ? 'active' : check2"
    >
      <div class="content">
        <h1 class="title">2</h1>
        <h3 class="subtitle" translate>buyTicket.step_identification</h3>
      </div>
    </div>

    <div
      class="pointerMidlle"
      id="step3"
      [ngClass]="step === 3 ? 'active' : check3"
    >
      <div class="content">
        <h1 class="title">3</h1>

        <h3 class="subtitle" translate>buyTicket.step_payment</h3>
      </div>
    </div>

    <div
      class="pointerReverse"
      id="step4"
      [ngClass]="step === 4 ? 'active' : check4"
    >
      <div class="content">
        <h1 class="title">4</h1>
        <h3 class="subtitle" translate>buyTicket.step_receipt</h3>
      </div>
    </div>
  </div>
</div>

<div class="container containerMainTimer" *ngIf="step != 4">
  <app-countdown></app-countdown>
</div>

<div *ngIf="step == 1">
  <div class="container containerContent">
    <div class="containerMain__days">
      <app-card-select-days
        (selectDate)="handlerStateBtn($event)"
        (selectedShift)="selectedShift($event)"
        (changeVacanciesAvailable)="changeVacanciesAvailable($event)"
      ></app-card-select-days>
    </div>
  </div>

  <div class="container-fluid containerButton">
    <div class="container contentButton">
      <div class="cardBtn">
        <app-button
          [isDisabled]="!isSelectedDay || !isSelectedShift"
          (click)="nextStep()"
          [isHidden]="true"
          text="{{ 'buyTicket.step_goto_identification' | translate }}"
        ></app-button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="step == 2">
  <div class="container">
    <div class="msg">
      <app-card-msg></app-card-msg>
    </div>

    <div class="cardMain mt-3">
      <div class="abstract">
        <app-abstract
          [qtdTotalTickets]="quantitiesTickets"
          [totalPrice]="totalPrice"
          [dataAbstract]="typeTicket"
        ></app-abstract>
      </div>

      <div class="typeTicket">
        <app-type-ticket
          [itensCardTypeTickets]="typeTicket"
          (setTicket)="setTickets($event)"
          (removeTickets)="removeTickets($event)"
          (onChangeQtdTicket)="getChangeQtd($event)"
          [qtyVacanciesAvailable]="qtyVacanciesAvailable"
        ></app-type-ticket>
      </div>
    </div>
    <div class="cardMain">
      <div class="abstract"></div>

      <div class="typeTicket">
        <ng-container [formGroup]="registerForm">
          <app-register
            (checkedIsForeign)="changeTypeInput($event)"
            formgroupName="datas"
            [qtdTouristsForm]="touristsCard"
            [addedTourist]="tourists"
            [removedeTourist]="touristRemove"
            (checkedDatas)="checkedDatas($event)"
            [isChecked]="isCheckedIfDatasTrue"
          ></app-register>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="container-fluid containerButton">
    <div class="container contentButton">
      <div class="cardBtn prev">
        <app-button
          [customerBtn]="customerBtn"
          (click)="openModalClearDatas()"
          [isHidden]="true"
          text="{{ 'global.go_back' | translate }}"
        ></app-button>
      </div>

      <div class="cardBtn">
        <button
          class="btn btn-primary btnCustome"
          [disabled]="!quantitiesTickets || registerForm.invalid || !isChecked"
          (click)="setDatasRegisterForm()"
        >
          {{ "buyTicket.step_goto_payment" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="step == 3">
  <div class="container containerAbsPay">
    <app-abstract
      title="Valor Total Da Compra"
      [qtdTotalTickets]="quantitiesTickets"
      [totalPrice]="totalPrice"
      [dataAbstract]="typeTicket"
    ></app-abstract>
  </div>

  <div class="container-fluid containerButton">
    <div class="container contentButton">
      <div class="cardBtn m-3">
        <app-button
          [customerBtn]="customerBtn"
          (click)="previousStep()"
          [isHidden]="true"
          text="{{ 'global.go_back' | translate }}"
        ></app-button>
      </div>

      <div class="cardBtn">
        <app-button
          (click)="nextStep()"
          [isHidden]="true"
          [text]="msgBtn"
        ></app-button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="step == 4">
  <div [hidden]="spinnerIsActive" class="container spinner">
    <div class="cardSpinner">
      <mat-spinner></mat-spinner>
    </div>
  </div>

  <div class="container cardMainMsg" [hidden]="isMsgErrorPay">
    <app-info-mgs [msg]="msgErrorPay"></app-info-mgs>
  </div>

  <div class="cardMainQrcode" [hidden]="!spinnerIsActive || !isMsgErrorPay">
    <app-receipt-tickets
      [ticketMain]="qrCodeMain"
      [ticketsSecondary]="qrCodeSecondary"
    ></app-receipt-tickets>

    <div class="container-fluid containerButton">
      <div class="container contentButton">
        <div class="cardBtn prev">
          <app-button
            [customerBtn]="customerBtn"
            routerLink="/"
            [isHidden]="true"
            text="{{ 'buyTicket.step_goto_home' | translate }}"
          ></app-button>
        </div>
        <div class="cardBtn">
          <a
            class="btnLink"
            [href]="linkUrlQrCode"
            (click)="openReceiptedTicket()"
            target="_blank"
            translate
          >
            buyTicket.step_print_receipt
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
