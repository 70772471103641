<div class="content" [id]="adress">
    <div class="cardAdress">
        <h2 class="title" translate>homePage.address.title</h2>
    </div>

    <div class="cardLocation">
        <div class="location">
            <i
                class="fa-solid fa-location-dot fa-xl icon"
                aria-hidden="true"
            ></i>
        </div>

        <p class="text" translate>homePage.address.localization</p>
    </div>

    <div class="cardCep">
        <div class="cardWeek">
            <p class="cep" translate>homePage.address.cep</p>
        </div>
    </div>
</div>
