<div [id]="hour" class="content">
    <div class="cardTitle">
        <h2 class="title" translate>homePage.opening_hours.title</h2>
    </div>

    <div class="cardDay">
        <div class="calendary">
            <i
                class="fa-solid fa-calendar-day fa-xl icon"
                aria-hidden="true"
            ></i>
        </div>

        <p
            class="text"
            [innerHTML]="'homePage.opening_hours.first_time' | translate"
        ></p>
    </div>

    <div class="cardDesc">
        <div class="cardWeek">
            <p
                class="week"
                [innerHTML]="'homePage.opening_hours.second_time' | translate"
            ></p>
        </div>
    </div>
</div>
