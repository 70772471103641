<div class="contentMain">
  <app-header
    [isHiddenBtnTranslate]="true"
    [defineTop]="defineTop"
  ></app-header>
  <div class="test">
    <app-header-menu
      [isHiddenBtn]="true"
      [isHiddenBtnInstitution]="true"
    ></app-header-menu>
  </div>
</div>

<div *ngIf="!isHidden">
  <div class="container-fluid containerMainStepper">
    <app-steps
      [configStep]="configStep"
      [pointerOne]="pointerOne"
      [isHiddenLastChild]="true"
      [step]="step"
      [check2]="checkStep2"
      [check3]="checkStep3"
    ></app-steps>
  </div>

  <div *ngIf="step === 1">
    <div class="container containerMain">
      <ng-container [formGroup]="formVisitRequest">
        <app-visit-request
          formGroupName="datas"
          (valuesTypePeople)="changeValueTypePeople($event)"
          (listHours)="getListHour($event)"
          [visitingHours]="visitingHours"
        >
        </app-visit-request>
      </ng-container>
    </div>

    <div class="container-fluid containerButton">
      <div class="container contentButton">
        <div class="cardBtn prev">
          <app-button
            [customerBtn]="customerBtn"
            routerLink="/"
            [isHidden]="true"
            text="Ir para home"
          ></app-button>
        </div>

        <div class="cardBtn">
          <button
            (click)="setDatasFormsVisitRequest()"
            [disabled]="
              formVisitRequest.invalid || (qtyAdults <= 0 && qtyElderlies <= 0)
            "
            class="btn btn-primary btnCustome"
            [ngStyle]="customerBtnNext"
          >
            {{ "buyTicketInstitution.step_goto_representative" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="step === 2">
    <div class="container containerMain">
      <ng-container [formGroup]="formRepresentative">
        <app-representative formGroupName="datas"></app-representative>
      </ng-container>
    </div>

    <div class="container-fluid containerButton">
      <div class="container contentButton">
        <div class="cardBtn prev">
          <app-button
            [customerBtn]="customerBtn"
            (click)="previousStep()"
            [isHidden]="true"
            text="{{ 'global.go_back' | translate }}"
          ></app-button>
        </div>

        <div class="cardBtn">
          <button
            (click)="setDatasFormRepresentative()"
            [disabled]="formRepresentative.invalid"
            class="btn btn-primary btnCustome"
            [ngStyle]="customerBtnNext"
          >
            {{ "buyTicketInstitution.step_goto_confirmation" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="step === 3">
    <div class="container containerMain">
      <app-display-datas
        [datasVisitRequest]="datasVisitRequest"
      ></app-display-datas>
    </div>

    <div class="container-fluid containerButton">
      <div class="container contentButton">
        <div class="cardBtn prev">
          <app-button
            [customerBtn]="customerBtn"
            (click)="previousStep()"
            [isHidden]="true"
            text="{{ 'global.go_back' | translate }}"
          ></app-button>
        </div>
        <div class="cardBtn">
          <app-button
            (click)="openModalConfirmRequestInstitution()"
            [isHidden]="true"
            text="{{
              'buyTicketInstitution.step_goto_confirm_data' | translate
            }}"
          ></app-button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container cardMainMsgRequest" *ngIf="isHidden">
  <app-msg-request [numberProtocol]="numberProtocol"></app-msg-request>
</div>
