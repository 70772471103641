<div class="contentMain">
    <app-header></app-header>
    <app-header-menu></app-header-menu>
</div>

<div class="container cardMainContainer">
    <app-title></app-title>

    <mat-card class="container containerMain">
        <div class="cardContentComponent" id="value">
            <app-card-values-ticket></app-card-values-ticket>
        </div>
        <div class="cardContentComponent">
            <app-carousel></app-carousel>
        </div>
    </mat-card>

    <mat-card class="container containerMain mt-4 hiddenCard" id="hour">
        <div class="cardContentComponent">
            <app-card-visit-institution> </app-card-visit-institution>
        </div>

        <div class="cardContentComponent"></div>
    </mat-card>
    <mat-card class="container containerMain mt-4" id="hour">
        <div class="cardContentComponent">
            <app-opening-hours></app-opening-hours>
        </div>

        <div class="cardContentComponent"></div>
    </mat-card>

    <mat-card class="container containerMain containerMain__columnInvert mt-4">
        <div class="cardContentComponent">
            <app-adress adress="adress"></app-adress>
        </div>

        <div class="cardContentComponent map">
            <app-map></app-map>
        </div>
    </mat-card>

    <mat-card class="container containerMain mt-4">
        <div class="cardContentComponent rulesCard">
            <app-rules-zoo rules="rules"></app-rules-zoo>
        </div>
    </mat-card>
</div>

<div class="divider" style="height: 40px"></div>
