import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  DateAdapter,
  MatNativeDateModule,
  MatRippleModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { Routes, RouterModule } from '@angular/router';
import { GalleryModule, GALLERY_CONFIG } from 'ng-gallery';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CountdownModule } from 'ngx-countdown';
import { ComponentsModule } from './components/components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PagesModule } from './pages/pages.module';
import { HomeComponent } from './pages/home/home.component';
import { BuyTicketComponent } from './pages/buy-ticket/buy-ticket.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { CloseScrollStrategy, Overlay } from '@angular/cdk/overlay';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BuyTicketInstitutionComponent } from './pages/buy-ticket-institution/buy-ticket-institution.component';
import { Error404Component } from './pages/error404/error404.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const format = {
  parse: {
    dateInput: ['LL'],
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },

  {
    path: 'buyticket',
    component: BuyTicketComponent,
  },
  {
    path: 'buyTicketInstitution',
    component: BuyTicketInstitutionComponent,
  },
  {
    path: '**',
    component: Error404Component,
  },
];

export function scrollFactory(overlay: Overlay): () => CloseScrollStrategy {
  return () => overlay.scrollStrategies.close();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    ComponentsModule,
    PagesModule,
    BrowserAnimationsModule,
    GalleryModule,
    RouterModule.forRoot(routes),
    CountdownModule,
    MatNativeDateModule,
    MatRippleModule,
    HttpClientModule,
    MatDatepickerModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      defaultLanguage: 'pt',
    }),
  ],
  providers: [
    DatePipe,
    {
      provide: GALLERY_CONFIG,
      useValue: {
        counter: false,
      },
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
    },

    { provide: MAT_DATE_LOCALE, useValue: 'pt-br' },
    {
      provide: MAT_DATE_FORMATS,
      useValue: format,
    },

    {
      provide: MAT_SELECT_SCROLL_STRATEGY,
      useFactory: scrollFactory,
      deps: [Overlay],
    },
  ],

  bootstrap: [AppComponent],
  exports: [MatDatepickerModule],
})
export class AppModule {}
