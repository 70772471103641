import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-pag-tesouro',
    templateUrl: './pag-tesouro.component.html',
    styleUrls: ['./pag-tesouro.component.scss'],
})
export class PagTesouroComponent {
    @Input() url: string;
}
