<div>
  <button
    [routerLink]="link"
    [disabled]="isDisabled"
    type="button"
    class="btn btn-primary btnCustome"
    [ngStyle]="customerBtn"
  >
    <mat-icon
      [hidden]="isHidden"
      style="margin-right: 10px"
      aria-hidden="false"
      aria-label="Example home icon"
      >{{ icon }}</mat-icon
    >
    {{ text }}
  </button>
</div>
