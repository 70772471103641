import { Component, OnInit } from '@angular/core';
import { GalleryItem, ImageItem } from 'ng-gallery';
@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
  images: GalleryItem[] = [];
  ngOnInit(): void {
    this.images = [
      new ImageItem({
        src: '../assets/images/01.jpg',
        thumb: '../assets/images/01.jpg',
      }),
      new ImageItem({
        src: '../assets/images/02.jpg',
        thumb: '../assets/images/02.jpg',
      }),
      new ImageItem({
        src: '../assets/images/03.jpg',
        thumb: '../assets/images/03.jpg',
      }),
      new ImageItem({
        src: '../assets/images/04.jpg',
        thumb: '../assets/images/04.jpg',
      }),
      new ImageItem({
        src: '../assets/images/05.jpg',
        thumb: '../assets/images/05.jpg',
      }),
      new ImageItem({
        src: '../assets/images/06.jpg',
        thumb: '../assets/images/06.jpg',
      }),
      new ImageItem({
        src: '../assets/images/07.png',
        thumb: '../assets/images/07.png',
      }),
      new ImageItem({
        src: '../assets/images/08.jpg',
        thumb: '../assets/images/08.jpg',
      }),
      new ImageItem({
        src: '../assets/images/09.jpg',
        thumb: '../assets/images/09.jpg',
      }),
      new ImageItem({
        src: '../assets/images/10.jpeg',
        thumb: '../assets/images/10.jpeg',
      }),
    ];
  }
}
