<div class="container">
  <div>
    <div class="cardHeader">
      <h3 class="title" translate>buyTicket.scheduling.date_title</h3>

      <h4 class="subtitle" translate>buyTicket.scheduling.date_description</h4>
    </div>

    <div class="cardMainCalendary">
      <mat-card class="calendarCard">
        <mat-calendar
          [minDate]="currentDateMin"
          (selectedChange)="getChangedValue($event)"
          [dateFilter]="disabledDaysFilter"
          [(selected)]="selected"
        ></mat-calendar>
      </mat-card>
    </div>
  </div>
</div>
