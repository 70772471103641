<div class="container-fluid">
    <header>
        <h3 class="title" translate>
            buyTicketInstitution.representative.form_title
        </h3>
    </header>

    <div class="container">
        <form [formGroup]="formRepresentative">
            <mat-card class="cardFrom">
                <h3 class="span m-0" translate>
                    buyTicketInstitution.representative.label_representative_name
                </h3>

                <mat-form-field appearance="outline" style="width: 100%">
                    <input
                        [errorStateMatcher]="matcher"
                        formControlName="name"
                        #nameRepresentative
                        placeholder="{{
                            'buyTicketInstitution.representative.placeholder_representative_name'
                                | translate
                        }}"
                        matInput
                        minlength="6"
                        maxlength="50"
                    />

                    <mat-error
                        *ngIf="!firstName?.hasError('required')"
                        translate
                    >
                        buyTicketInstitution.representative.error_representative_name
                    </mat-error>

                    <mat-error
                        *ngIf="firstName?.hasError('required')"
                        translate
                    >
                        global.required_field
                    </mat-error>
                </mat-form-field>

                <mat-hint class="contCaracName" align="end">
                    {{ nameRepresentative.value?.length || 0 }}/50
                </mat-hint>

                <h3 class="span m-0" translate>
                    buyTicketInstitution.representative.label_function
                </h3>

                <mat-form-field appearance="outline" style="width: 100%">
                    <input
                        [errorStateMatcher]="matcher"
                        formControlName="post"
                        #role
                        placeholder="{{
                            'buyTicketInstitution.representative.placeholder_function'
                                | translate
                        }}"
                        matInput
                        minlength="7"
                        maxlength="16"
                    />

                    <mat-error *ngIf="!post?.hasError('required')" translate>
                        buyTicketInstitution.representative.error_function
                    </mat-error>

                    <mat-error *ngIf="post?.hasError('required')" translate>
                        global.required_field
                    </mat-error>
                </mat-form-field>
                <mat-hint class="contCaracRole" align="end">
                    {{ role.value?.length || 0 }}/16
                </mat-hint>

                <div class="cardEmailAndPhone">
                    <div class="typeInput">
                        <h3 class="span m-0" translate>
                            buyTicketInstitution.representative.label_email
                        </h3>

                        <mat-form-field
                            appearance="outline"
                            style="width: 100%"
                        >
                            <input
                                [errorStateMatcher]="matcher"
                                formControlName="email"
                                placeholder="{{
                                    'buyTicketInstitution.representative.placeholder_email'
                                        | translate
                                }}"
                                matInput
                            />

                            <mat-error
                                *ngIf="!email?.hasError('required')"
                                translate
                            >
                                buyTicketInstitution.representative.error_email
                            </mat-error>

                            <mat-error
                                *ngIf="email?.hasError('required')"
                                translate
                            >
                                global.required_field
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div class="typeInput">
                        <h3 class="span m-0" translate>
                            buyTicketInstitution.representative.label_contact_phone
                        </h3>

                        <mat-form-field
                            appearance="outline"
                            style="width: 100%"
                        >
                            <input
                                mask="(00) 0 0000-0000"
                                formControlName="phone_number"
                                placeholder="{{
                                    'buyTicketInstitution.representative.placeholder_contact_phone'
                                        | translate
                                }}"
                                matInput
                            />

                            <mat-error translate>
                                global.required_field
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </mat-card>
        </form>
    </div>
</div>
