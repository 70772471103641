import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-card-msg',
    templateUrl: './card-msg.component.html',
    styleUrls: ['./card-msg.component.scss'],
})
export class CardMsgComponent {
    @Input() msg: string =
        'Reagendamento deve ser presencial na bilheteria do zoológico.';

    ngOnInit() {
        let current_lang = localStorage.getItem('lang')|| 'pt';

        if (current_lang === 'en') {
            this.msg =
                'Rescheduling must be in person at the zoo ticket office.';
        } else {
            this.msg =
                'Reagendamento deve ser presencial na bilheteria do zoológico.';
        }
    }
}
