import { Component, Inject, Input } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';

@Component({
  selector: 'app-modal-pag',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponentPagTesouro {
  @Input() paym_next_url: string;
  @Input() currentTicketId: number;
  textBtnYes: string;
  textBtnDont: string;

  selected_lang: any = localStorage.getItem('lang') || 'pt';

  teste: boolean = false;

  customerBtn: any = {
    background: '#fa5b79',
  };
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      url: string;
      currentTicketId: number;
      textBtnYes: string;
      textBtnDont: string;
    },
    public dialog: MatDialog
  ) {}

  openModalCancel() {
    const dialog = this.dialog.open(DialogComponent, {
      data: {
        customerBtn: { background: '#FA5B79' },
        title: this.selected_lang === 'pt' ? 'Atenção!' : 'Heads up',
        msg:
          this.selected_lang === 'pt'
            ? 'Seus dados serão perdidos, deseja mesmo sair ?'
            : 'Your will be Lost Data, do you really want to exit?',
        textBtnYes: this.selected_lang === 'pt' ? 'Sair' : 'Exit',
        textBtnDont: this.selected_lang === 'pt' ? 'Não' : 'No',
      },
      disableClose: true,
    });

    dialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.dialog.closeAll();
      }
    });
  }

  closeModal() {
    this.dialog.closeAll();
  }
}
