import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

interface ILanguage {
  label: string;
  value: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  langs: Array<ILanguage> = [];
  selected_lang: any = localStorage.getItem('lang') || 'pt';

  constructor(private translate: TranslateService) {
    this.langs = [
      { label: 'Português', value: 'pt' },
      { label: 'English', value: 'en' },
    ];

    translate.setDefaultLang('pt');
    translate.use('pt');
  }

  @Input() defineTop: string;
  @Input() isHiddenBtnTranslate: boolean = false;

  handleChangeLanguage(item: any) {
    localStorage.setItem('lang', item);
    this.translate.use(item);
    window.location.reload();
  }

  ngOnInit() {
    if (localStorage.getItem('lang')) {
      this.selected_lang = localStorage.getItem('lang') || 'pt';
      this.translate.use(this.selected_lang);
    } else {
      this.selected_lang = localStorage.setItem('lang', 'pt');
      this.selected_lang = localStorage.getItem('lang') || 'pt';
      this.translate.use(this.selected_lang);
    }
  }
}
