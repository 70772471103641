import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Scheduling } from 'src/app/models/scheduling';
import { SchedulingService } from 'src/app/services/scheduling.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-card-select-days',
  templateUrl: './card-select-days.component.html',
  styleUrls: ['./card-select-days.component.scss'],
})
export class CardSelectDaysComponent implements OnInit {
  msg: string;
  qtyVacanciesAvailableMorning: number = 0;
  qtyVacanciesAvailableAfternoon: number;
  isDisabledMorning: boolean = true;
  isDisabledAfternoon: boolean = true;
  isShiftSelectedMorning: boolean = false;
  isShiftSelectedAfternoon: boolean = false;
  schedulings: Scheduling;
  currentDate: Date = new Date();
  isMonday: boolean = false;
  currentHour: number;

  @Output() selectedShift: EventEmitter<any> = new EventEmitter();
  @Output() selectDate: EventEmitter<any> = new EventEmitter<any>();
  @Output() changeVacanciesAvailable: EventEmitter<any> =
    new EventEmitter<any>();

  constructor(
    private schedulingService: SchedulingService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    let date = this.currentDate;
    const currentDateToString = date;
    this.currentHour = currentDateToString.getHours();
    this.showMsgDefaultIfCms();
  }

  setDayScheduling(date: any) {
    let dateCurrent = this.currentDate;
    let dateToString = this.datePipe.transform(dateCurrent, 'yyyy-MM-dd');

    this.schedulingService.getOperationDayByDate(date).subscribe({
      next: (res) => {
        if (res.active === true) {
          this.qtyVacanciesAvailableMorning = res.tickets_available_morning;
          this.qtyVacanciesAvailableAfternoon = res.tickets_available_afternoon;

          if (res.day === dateToString) {
            this.isDisabledMorning = true;
            this.isDisabledAfternoon = true;
            if (
              this.qtyVacanciesAvailableMorning > 0 &&
              this.currentHour < 12
            ) {
              this.isDisabledMorning = false;
            }
            if (
              this.qtyVacanciesAvailableAfternoon > 0 &&
              this.currentHour < 16
            ) {
              this.isDisabledAfternoon = false;
            }
            if (
              this.qtyVacanciesAvailableMorning > 0 &&
              this.qtyVacanciesAvailableAfternoon > 0
            ) {
              if (this.currentHour >= 12 && this.currentHour < 16) {
                this.isDisabledMorning = true;
                this.isDisabledAfternoon = false;
              } else if (this.currentHour >= 16) {
                this.isDisabledMorning = true;
                this.isDisabledAfternoon = true;
              }
            }
          } else {
            if (this.qtyVacanciesAvailableMorning > 0) {
              this.isDisabledMorning = false;
            } else {
              this.isDisabledMorning = true;
            }
            if (this.qtyVacanciesAvailableAfternoon > 0) {
              this.isDisabledAfternoon = false;
            } else {
              this.isDisabledAfternoon = true;
            }
          }
        } else {
          this.isDisabledAfternoon = true;
          this.isDisabledMorning = true;
        }
        this.schedulings = res;
      },
      error: () => {
        this.isDisabledAfternoon = true;
        this.isDisabledMorning = true;
      },
    });
  }

  selectedDate(date: Date) {
    this.isMonday = false;
    this.isShiftSelectedMorning = false;
    this.isShiftSelectedAfternoon = false;
    this.selectDate.emit(true);
    let dateToString = date;
    let dateTransformToString = this.datePipe.transform(
      dateToString,
      'yyyy-MM-dd'
    );

    this.setDayScheduling(dateTransformToString);
  }

  selectShiftScheduling(
    id: number | undefined,
    shift: 'SHIFT_MORNING' | 'SHIFT_AFTERNOON'
  ) {
    if (shift === 'SHIFT_MORNING') {
      this.isShiftSelectedMorning = true;
      this.selectedShift.emit({ id: id, shift: shift });

      this.changeVacanciesAvailable.emit({
        value: this.qtyVacanciesAvailableMorning,
        shift: shift,
      });
    } else {
      this.isShiftSelectedMorning = false;
    }
    if (shift === 'SHIFT_AFTERNOON') {
      this.isShiftSelectedAfternoon = true;
      this.selectedShift.emit({ id: id, shift: shift });
      this.changeVacanciesAvailable.emit({
        value: this.qtyVacanciesAvailableAfternoon,
        shift: shift,
      });
    } else {
      this.isShiftSelectedAfternoon = false;
    }
  }

  showMsgDefaultIfCms() {
    this.schedulingService
      .getMsgDefault('message_default')
      .subscribe((res) => (this.msg = res.value_settings.message_default));
  }
}
