import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent {
  constructor(private router: Router, private dialog: MatDialog) {}
  config: CountdownConfig = { leftTime: 300, format: 'mm:ss' };

  handleEvent(e: CountdownEvent) {
    if (e.action === 'done') {
      this.openModal();
    }
  }

  openModal() {
    let selected_lang: any = localStorage.getItem('lang') || 'pt';

    const dialog = this.dialog.open(DialogComponent, {
      data: {
        title: selected_lang === 'pt' ? 'Atenção' : 'Heads up',
        msg:
          selected_lang === 'pt'
            ? 'Que pena seu tempo de compra esgotou!'
            : 'What a pity your purchase time has run out!',
        isHidden: true,
        textBtnYes: 'OK',
      },
      disableClose: true,
    });

    dialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.router.navigate(['']);
      }
    });
  }
}
