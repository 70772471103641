import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: 'app-representative',
  templateUrl: './representative.component.html',
  styleUrls: ['./representative.component.scss'],
})
export class RepresentativeComponent implements OnInit {
  @Input() formRepresentative: FormGroup;
  @Input() formGroupName: string;
  matcher = new MyErrorStateMatcher();

  constructor(
    private fb: FormBuilder,
    private rootFormGroup: FormGroupDirective
  ) {}

  ngOnInit() {
    this.createFormRepresentative();
  }

  createFormRepresentative() {
    this.formRepresentative = this.rootFormGroup.control.get(
      this.formGroupName
    ) as FormGroup;
  }

  get firstName() {
    return this.formRepresentative.get('name');
  }
  get post() {
    return this.formRepresentative.get('post');
  }

  get email() {
    return this.formRepresentative.get('email');
  }
}
