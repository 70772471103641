import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { debounceTime, pluck } from 'rxjs';
import { DialogComponent } from 'src/app/components/dialog/dialog.component';

import { ConfigStep } from 'src/app/models/configStep';

import { TicketInstitution } from 'src/app/models/ticket-institution';
import { InstitutionService } from 'src/app/services/institution.service';

@Component({
  selector: 'app-buy-ticket-institution',
  templateUrl: './buy-ticket-institution.component.html',
  styleUrls: ['./buy-ticket-institution.component.scss'],
})
export class BuyTicketInstitutionComponent implements OnInit {
  formRepresentative: FormGroup;
  formVisitRequest: FormGroup;
  pointerOne: any = { width: '360px' };
  defineTop: any = { top: '127px' };
  selected_lang: any = localStorage.getItem('lang')|| 'pt';

  configStep: ConfigStep = {
    textStep1: 'Solicitação de Visita',
    textStep2: 'Representante',
    textStep3: 'Confirmação',
  };

  customerBtn: any = {
    'background-color': 'var(--color-blue-light)',
  };
  customerBtnNext: any = {
    'background-color': 'var(--color-secondary)',
  };
  ticketInstitution: TicketInstitution = new TicketInstitution();
  datasVisitRequest: TicketInstitution = new TicketInstitution();
  visitingHours: any[] = [];
  checkStep2: string;
  checkStep3: string;
  step: number = 1;
  numberProtocol: number;
  qtyAdults: number = 0;
  qtyElderlies: number = 0;
  isHidden: boolean = false;
  typePeople: 'above12' | 'until12' | 'adults' | 'elderlies' | 'pcds';

  constructor(
    private fb: FormBuilder,
    private institutionService: InstitutionService,
    private datePipe: DatePipe,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    window.scroll(0, 0);
    this.createFormRepresentative();
    this.createFormVisitRequest(new TicketInstitution());
    this.replaceData();
    localStorage.setItem('lang', 'pt');
  }

  createFormRepresentative() {
    this.formRepresentative = this.fb.group({
      datas: this.fb.group({
        name: ['', [Validators.required]],
        post: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        phone_number: ['', [Validators.required]],
      }),
    });
  }

  createFormVisitRequest(ticketInstitution: TicketInstitution) {
    this.formVisitRequest = this.fb.group({
      datas: this.fb.group({
        institution_type: [
          ticketInstitution.institution_type,
          [Validators.required],
        ],
        institution_name: [
          ticketInstitution.institution_name,
          [
            Validators.required,
            Validators.minLength(20),
            Validators.maxLength(50),
          ],
        ],

        institution_visit_date: [
          ticketInstitution.institution_visit_date,
          [Validators.required],
        ],

        institution_visit_time: [
          ticketInstitution.institution_visit_time,
          [Validators.required],
        ],

        people: this.fb.group({
          until_12: [0],
          above_12: [0],
          adults: [0],
          elderlies: [0],
          pcds: [0],
        }),
      }),
    });
  }

  setDatasFormsVisitRequest() {
    const visitRequest = this.ticketInstitution;
    const valueFormVisitRequest: TicketInstitution =
      this.formVisitRequest.value.datas;

    visitRequest.institution_name = valueFormVisitRequest.institution_name;
    visitRequest.institution_type = valueFormVisitRequest.institution_type;
    visitRequest.institution_visit_date = this.transformDate(
      valueFormVisitRequest.institution_visit_date
    ) as string;

    visitRequest.institution_visit_time =
      valueFormVisitRequest.institution_visit_time;

    visitRequest.people.above_12 = Number(
      valueFormVisitRequest.people.above_12
    );
    visitRequest.people.pcds = Number(valueFormVisitRequest.people.pcds);
    visitRequest.people.elderlies = Number(
      valueFormVisitRequest.people.elderlies
    );
    visitRequest.people.adults = Number(valueFormVisitRequest.people.adults);
    visitRequest.people.until_12 = Number(
      valueFormVisitRequest.people.until_12
    );
    this.datasVisitRequest = visitRequest;

    this.nextStep();
  }

  setDatasFormRepresentative() {
    const representative = this.ticketInstitution;
    const valueFormRepresentative: any = this.formRepresentative.value.datas;

    representative.agent.name = valueFormRepresentative.name;
    representative.agent.email = valueFormRepresentative.email;
    representative.agent.post = valueFormRepresentative.post;
    representative.agent.phone_number = valueFormRepresentative.phone_number;

    this.datasVisitRequest = representative;
    this.nextStep();
  }

  nextStep() {
    if (this.step === 3) {
      return;
    }
    this.step++;

    if (this.step === 3) {
      this.checkStep2 = 'checked';
    }
  }

  previousStep() {
    if (this.step === 1) {
      return;
    }
    this.step--;
    if (this.step === 2) {
      this.checkStep2 = '';
    }
  }

  createTicketInstitution() {
    this.institutionService
      .createTicketInstitution(this.datasVisitRequest)
      .pipe(pluck('created_institution_visit'))
      .subscribe({
        next: (res) => {
          const response = res as TicketInstitution;
          this.numberProtocol = response.id as number;
        },
        error: (error: HttpErrorResponse) => console.log(error),
      });
  }

  transformDate(date: Date | any) {
    const dateTransform = this.datePipe.transform(date, 'YYYY-MM-dd');
    return dateTransform;
  }

  changeValueTypePeople(value: any) {
    if (value.type === 'adults') {
      this.qtyAdults = value.value;
    }
    if (value.type === 'elderlies') {
      this.qtyElderlies = value.value;
    }
  }

  openModalConfirmRequestInstitution() {
    const dialog = this.dialog.open(DialogComponent, {
      data: {
        paddingMsg: { padding: '19px 19px' },
        customerBtn: { background: '#2a6fdb' },
        widthBtn: { width: '240px' },
        isHiddenIcon: true,
        title: 'Confirmar solicitação de visita?',
        msg: 'Deseja confirmar sua solicitação de visita para sua instituição?',
        textBtnYes: 'Confirmar Solicitação',
        textBtnDont: 'Voltar',
        typeBuyTicket: 'buyTicketInstitution',
      },
    });

    dialog.afterClosed().subscribe((result) => {
      if (result === true) {
        this.createTicketInstitution();
        this.isHidden = true;
      }
    });
  }

  replaceData() {
    this.formVisitRequest.controls['datas']
      .get('institution_name')
      ?.valueChanges.pipe(debounceTime(1))
      .subscribe((value: string) =>
        this.formVisitRequest.controls['datas'].patchValue({
          institution_name: value
            .replace(/(^\s)|(\d)|([^a-zA-Z 0-9 áéíóúçâêôãõ])/g, '')
            .replace('  ', ' '),
        })
      );
    this.formRepresentative.controls['datas']
      .get('name')
      ?.valueChanges.pipe(debounceTime(1))
      .subscribe((value: string) =>
        this.formRepresentative.controls['datas'].patchValue({
          name: value
            .replace(/(^\s)|(\d)|([^a-zA-Z 0-9 áéíóúçâêôãõ])/g, '')
            .replace('  ', ' '),
        })
      );
    this.formRepresentative.controls['datas']
      .get('post')
      ?.valueChanges.pipe(debounceTime(1))
      .subscribe((value: string) =>
        this.formRepresentative.controls['datas'].patchValue({
          post: value
            .replace(/(^\s)|(\d)|([^a-zA-Z 0-9 áéíóúçâêôãõ])/g, '')
            .replace('  ', ' '),
        })
      );
  }

  getListHour(value: any) {
    this.visitingHours = value;
  }
}
