<div class="container containerContent">
  <div class="cardTypeTicket">
    <div class="cardHeader">
      <h3 class="title header" translate>
        buyTicket.identification.tickets_types_title
      </h3>

      <h4 class="subtitle subtitle--modify" translate>
        buyTicket.identification.tickets_types_description
      </h4>
    </div>
  </div>

  <div class="container p-0">
    <mat-card
      class="card"
      *ngFor="let itenCardTypeTicket of itensCardTypeTickets"
    >
      <div class="container card__content">
        <div class="contentItems">
          <h3 class="title">{{ itenCardTypeTicket.ticket_name }}</h3>

          <div class="container contentActions p-0">
            <div
              class="input-group groupInput"
              [id]="'cardItem' + itenCardTypeTicket.ticket_id"
            >
              <span
                (click)="removeTicket(itenCardTypeTicket.ticket_id)"
                class="input-group-text spanCustomize"
                ><i class="fa-solid fa-minus colorGray" aria-hidden="true"></i
              ></span>

              <div class="cardQtd">
                {{ itenCardTypeTicket.qtdTickets }}
              </div>

              <span
                [ngClass]="qtyVacanciesAvailable === 0 ? 'active' : ''"
                (click)="addTicket(itenCardTypeTicket.ticket_id)"
                class="input-group-text spanCustomize"
                ><i class="fa-solid fa-plus colorGray" aria-hidden="true"></i
              ></span>
            </div>
          </div>
        </div>

        <div class="cardSubtitle">
          <div
            class="subtitle"
            [innerHTML]="itenCardTypeTicket.ticket_description"
          ></div>
        </div>

        <div
          class="cardMainMsg"
          [id]="itenCardTypeTicket.ticket_name"
          *ngIf="itenCardTypeTicket.ticket_message"
        >
          <div class="content cardMainMsg__msg">
            <p class="m-0 msg">
              {{ itenCardTypeTicket.ticket_message }}
            </p>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
